var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "publish-container" }, [
    _c(
      "div",
      { staticClass: "form-content" },
      [
        _vm.examineShow
          ? _c("div", { staticClass: "transparentBox" })
          : _vm._e(),
        _c(
          "el-form",
          {
            ref: "publishForm",
            attrs: {
              inline: "",
              model: _vm.publishForm,
              rules: _vm.publishFormRules,
              "label-width": "130px",
            },
          },
          [
            _c("div", { staticClass: "basicBox" }, [
              _c("div", { staticClass: "titleFont" }, [_vm._v("基本信息")]),
              _c(
                "div",
                { staticClass: "basic" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { label: "产品图片：", prop: "picUrl" },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.uploadPath,
                            "list-type": "picture-card",
                            "show-file-list": false,
                            "on-success": _vm.handleUploadSuccess,
                            "before-upload": _vm.beforeUpload,
                          },
                        },
                        [
                          _vm.publishForm.picUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.publishForm.picUrl },
                              })
                            : _c("i", { staticClass: "el-icon-plus" }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "订单模式：", prop: "orderProcess" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "select-style",
                          attrs: { placeholder: "请选择", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.changeOrderProcess(
                                _vm.publishForm.orderProcess
                              )
                            },
                          },
                          model: {
                            value: _vm.publishForm.orderProcess,
                            callback: function ($$v) {
                              _vm.$set(_vm.publishForm, "orderProcess", $$v)
                            },
                            expression: "publishForm.orderProcess",
                          },
                        },
                        _vm._l(_vm.paymentmethod.orderProcess, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "产品名称：", prop: "productName" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-width",
                        attrs: {
                          placeholder: "请输入产品名称",
                          size: "small",
                          maxlength: "50",
                        },
                        model: {
                          value: _vm.publishForm.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "productName", $$v)
                          },
                          expression: "publishForm.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "产品类型：", prop: "categoryId" },
                    },
                    [
                      _c("el-cascader", {
                        staticClass: "input-width",
                        attrs: { options: _vm.categoryList, size: "small" },
                        model: {
                          value: _vm.publishForm.categoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "categoryId", $$v)
                          },
                          expression: "publishForm.categoryId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "规格型号：", prop: "productSpec" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-width",
                        attrs: {
                          placeholder: "请输入产品型号",
                          size: "small",
                          maxlength: "20",
                        },
                        model: {
                          value: _vm.publishForm.productSpec,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "productSpec", $$v)
                          },
                          expression: "publishForm.productSpec",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "需求量：", prop: "reqNum" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-width",
                        staticStyle: { width: "172px", "margin-right": "8px" },
                        attrs: {
                          maxlength: "10",
                          placeholder: "请输入需求量",
                          size: "small",
                        },
                        nativeOn: {
                          input: function ($event) {
                            return _vm.valueReqNum($event, "reqNum")
                          },
                        },
                        model: {
                          value: _vm.publishForm.reqNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "reqNum", $$v)
                          },
                          expression: "publishForm.reqNum",
                        },
                      }),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: {
                            placeholder: "请选择单位",
                            "allow-create": "",
                            filterable: "",
                            "default-first-option": "",
                            size: "small",
                          },
                          model: {
                            value: _vm.publishForm.unit,
                            callback: function ($$v) {
                              _vm.$set(_vm.publishForm, "unit", $$v)
                            },
                            expression: "publishForm.unit",
                          },
                        },
                        _vm._l(_vm.unitList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: {
                        size: "small",
                        label: "合同模板：",
                        prop: "contractInfo",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "contract-select",
                          staticStyle: { width: "300px" },
                          attrs: {
                            placeholder: "请选择合同模板",
                            "popper-append-to-body": false,
                          },
                          model: {
                            value: _vm.publishForm.contractInfo,
                            callback: function ($$v) {
                              _vm.$set(_vm.publishForm, "contractInfo", $$v)
                            },
                            expression: "publishForm.contractInfo",
                          },
                        },
                        _vm._l(_vm.contractList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.tContractTextNo,
                              staticClass: "contract-option",
                              attrs: {
                                id: "contract-id",
                                label: item.tContractTextName,
                                value: item.tContractTextNo,
                              },
                            },
                            [
                              _c("div", { staticClass: "contract-box" }, [
                                _c("div", { staticClass: "contract-left" }, [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(_vm._s(item.tContractTextName)),
                                  ]),
                                  _c("span", { staticClass: "num" }, [
                                    _vm._v(_vm._s(item.tContractTextNo)),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "contract-right",
                                    staticStyle: { color: "#8492a6" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showContractDialog(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v(" 查看 ")]
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "合同编号：", prop: "contractNumber" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-width",
                        attrs: {
                          maxlength: "50",
                          placeholder: "请输入合同编号",
                          size: "small",
                          onkeyup:
                            "this.value=this.value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                        },
                        nativeOn: {
                          input: function ($event) {
                            return _vm.valueNumber($event, "contractNumber")
                          },
                        },
                        model: {
                          value: _vm.publishForm.contractNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "contractNumber", $$v)
                          },
                          expression: "publishForm.contractNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "basicBox" }, [
              _c("div", { staticClass: "titleFont" }, [_vm._v("价格信息")]),
              _c(
                "div",
                { staticClass: "basic" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "最高竞买价(元)：", prop: "maxPrice" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-width",
                        attrs: {
                          maxlength: 13,
                          placeholder: "请输入最高竞买价",
                          size: "small",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.blurmethod($event, "maxPrice")
                          },
                        },
                        nativeOn: {
                          input: function ($event) {
                            return _vm.keyup($event, "maxPrice")
                          },
                        },
                        model: {
                          value: _vm.publishForm.maxPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "maxPrice", $$v)
                          },
                          expression: "publishForm.maxPrice",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "价格调整幅度(元)：", prop: "stepPrice" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-width",
                        attrs: {
                          maxlength: 13,
                          placeholder: "请输入价格调整幅度",
                          size: "small",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.blurmethod($event, "stepPrice")
                          },
                        },
                        nativeOn: {
                          input: function ($event) {
                            return _vm.keyup($event, "stepPrice")
                          },
                        },
                        model: {
                          value: _vm.publishForm.stepPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "stepPrice", $$v)
                          },
                          expression: "publishForm.stepPrice",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "有效期：", prop: "dateTime" },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "130px" },
                        attrs: {
                          size: "small",
                          "picker-options": _vm.pickerStartOptions,
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "开始日期",
                        },
                        on: { change: _vm.onValidityDateChange },
                        model: {
                          value: _vm.publishForm.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "date", $$v)
                          },
                          expression: "publishForm.date",
                        },
                      }),
                      _c(
                        "span",
                        { on: { click: _vm.nowTime } },
                        [
                          _c("el-time-picker", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              "is-range": "",
                              size: "small",
                              "value-format": "HH:mm",
                              format: "HH:mm",
                              "range-separator": "-",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              placeholder: "选择时间范围",
                            },
                            on: { change: _vm.onValidityTimeChange },
                            model: {
                              value: _vm.publishForm.time,
                              callback: function ($$v) {
                                _vm.$set(_vm.publishForm, "time", $$v)
                              },
                              expression: "publishForm.time",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "收货信息：", prop: "addressId" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "select-style",
                          attrs: {
                            placeholder: "请选择收货信息",
                            size: "small",
                            "popper-append-to-body": false,
                          },
                          model: {
                            value: _vm.publishForm.addressId,
                            callback: function ($$v) {
                              _vm.$set(_vm.publishForm, "addressId", $$v)
                            },
                            expression: "publishForm.addressId",
                          },
                        },
                        _vm._l(_vm.userAddressList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              staticClass: "self-select",
                              attrs: { label: item.info, value: item.id },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.name) + " " + _vm._s(item.mobile)
                                ),
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(_vm._s(item.detailedAddress)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "basicBox" }, [
              _c("div", { staticClass: "titleFont" }, [_vm._v("价格信息")]),
              _c(
                "div",
                { staticClass: "basic" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form-item",
                          attrs: {
                            size: "small",
                            label: "发票类型：",
                            prop: "invoiceType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.publishForm.invoiceType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.publishForm, "invoiceType", $$v)
                                },
                                expression: "publishForm.invoiceType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "0" } }, [
                                _vm._v("专用发票"),
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { disabled: "", label: "1" } },
                                [_vm._v("普通发票")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form-item",
                          attrs: {
                            label: "适用税率(%)：",
                            prop: "arbAdditionInfo.taxRate",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input-width",
                            attrs: { placeholder: "请输入税率", size: "mini" },
                            nativeOn: {
                              input: function ($event) {
                                return _vm.valueChange($event, "taxRate")
                              },
                            },
                            model: {
                              value: _vm.publishForm.arbAdditionInfo.taxRate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.publishForm.arbAdditionInfo,
                                  "taxRate",
                                  $$v
                                )
                              },
                              expression: "publishForm.arbAdditionInfo.taxRate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: {
                        label: "开票时间：",
                        prop: "arbAdditionInfo.invsStatus",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.publishForm.arbAdditionInfo.invsStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.publishForm.arbAdditionInfo,
                                "invsStatus",
                                $$v
                              )
                            },
                            expression:
                              "publishForm.arbAdditionInfo.invsStatus",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "KQ" } }, [
                            _vm._v("付款前开发票"),
                          ]),
                          _c("el-radio", { attrs: { label: "KH" } }, [
                            _vm._v("付款后开发票"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item-invsDay",
                      attrs: { prop: "arbAdditionInfo.invsDay" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { "font-size": "14px" },
                          style: {
                            color:
                              _vm.publishForm.arbAdditionInfo.invsStatus == "KH"
                                ? "#000"
                                : "#c0c4cc",
                          },
                        },
                        [
                          _vm._v(" 付款之日起【"),
                          _c("el-input", {
                            staticClass: "input-width-small",
                            attrs: { disabled: this.invsDayShow, size: "mini" },
                            model: {
                              value: _vm.publishForm.arbAdditionInfo.invsDay,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.publishForm.arbAdditionInfo,
                                  "invsDay",
                                  $$v
                                )
                              },
                              expression: "publishForm.arbAdditionInfo.invsDay",
                            },
                          }),
                          _vm._v("】个工作日内开票 "),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "el-row",
              { attrs: { gutter: 60 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 21 } },
                  [
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "合同模板",
                          visible: _vm.contractDialogVisible,
                          width: "700px",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.contractDialogVisible = $event
                          },
                        },
                      },
                      [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.contractTemplate) },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "btnBoxStyle" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btnstyle",
                    attrs: { type: "primary" },
                    on: { click: _vm.next },
                  },
                  [_vm._v("下一步")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
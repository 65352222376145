var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "publish-container" }, [
    _c(
      "div",
      { staticClass: "form-content" },
      [
        _vm.examineShow
          ? _c("div", { staticClass: "transparentBox" })
          : _vm._e(),
        _c(
          "el-form",
          {
            ref: "publishForm",
            attrs: {
              inline: "",
              model: _vm.publishForm,
              rules: _vm.publishFormRules,
              "label-width": "130px",
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-left": "190px" },
                attrs: { label: "竞买要求：", prop: "reqRemark" },
              },
              [
                _c("tinymce-editor", {
                  staticStyle: { width: "672px；" },
                  attrs: { value: _vm.publishForm.reqRemark },
                  on: {
                    input: function ($event) {
                      _vm.publishForm.reqRemark = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "btnBoxStyle" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btnLast",
                    attrs: { type: "primary" },
                    on: { click: _vm.last },
                  },
                  [_vm._v("上一步")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btnNext",
                    attrs: { type: "primary" },
                    on: { click: _vm.next },
                  },
                  [_vm._v("下一步")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "publish-container" }, [
    _c(
      "div",
      { staticClass: "form-content" },
      [
        _vm.examineShow
          ? _c("div", { staticClass: "transparentBox" })
          : _vm._e(),
        _c(
          "el-form",
          {
            ref: "publishForm",
            attrs: {
              inline: "",
              model: _vm.publishForm,
              rules: _vm.publishFormRules,
              "label-width": "130px",
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticClass: "form-item",
                attrs: {
                  label: "包装方式：",
                  prop: "arbAdditionInfo.packageMode",
                },
              },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "select-style",
                    attrs: { placeholder: "请选择", size: "small" },
                    model: {
                      value: _vm.publishForm.arbAdditionInfo.packageMode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.publishForm.arbAdditionInfo,
                          "packageMode",
                          $$v
                        )
                      },
                      expression: "publishForm.arbAdditionInfo.packageMode",
                    },
                  },
                  _vm._l(_vm.paymentmethod.packageModeData, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "验收条件：",
                  prop: "arbAdditionInfo.checkMode",
                },
              },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "select-style",
                    attrs: { placeholder: "请选择", size: "small" },
                    model: {
                      value: _vm.publishForm.arbAdditionInfo.checkMode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.publishForm.arbAdditionInfo,
                          "checkMode",
                          $$v
                        )
                      },
                      expression: "publishForm.arbAdditionInfo.checkMode",
                    },
                  },
                  _vm._l(_vm.paymentmethod.checkModeData, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "form-item",
                    attrs: { label: "交货时间(天)：", prop: "deliverDay" },
                  },
                  [
                    _c("el-input", {
                      staticClass: "input-width",
                      attrs: {
                        maxlength: "12",
                        placeholder: "请输入交货时间",
                        size: "small",
                      },
                      model: {
                        value: _vm.publishForm.deliverDay,
                        callback: function ($$v) {
                          _vm.$set(_vm.publishForm, "deliverDay", _vm._n($$v))
                        },
                        expression: "publishForm.deliverDay",
                      },
                    }),
                    _c("div", { staticClass: "hint" }, [
                      _vm._v("订单成交后 T+N 天内交货"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "form-item-invsStatus",
                    attrs: {
                      label: "交货方式：",
                      prop: "arbAdditionInfo.deliverMode",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.publishForm.arbAdditionInfo.deliverMode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.publishForm.arbAdditionInfo,
                              "deliverMode",
                              $$v
                            )
                          },
                          expression: "publishForm.arbAdditionInfo.deliverMode",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "SH" } }, [
                          _vm._v("供应商送货"),
                        ]),
                        _c("el-radio", { attrs: { label: "ZT" } }, [
                          _vm._v("买家自提"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form-item-invsStatus",
                    style: {
                      color:
                        _vm.publishForm.arbAdditionInfo.deliverMode == "ZT"
                          ? "#000"
                          : "#c0c4cc",
                    },
                    attrs: { prop: "arbAdditionInfo.deliverModeDay" },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { "font-size": "14px" } },
                      [
                        _vm._v(" 卖方通知提货之日起【"),
                        _c("el-input", {
                          staticClass: "input-width-small",
                          attrs: {
                            size: "mini",
                            disabled:
                              _vm.publishForm.arbAdditionInfo.deliverMode ==
                              "ZT"
                                ? false
                                : true,
                          },
                          model: {
                            value:
                              _vm.publishForm.arbAdditionInfo.deliverModeDay,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.publishForm.arbAdditionInfo,
                                "deliverModeDay",
                                $$v
                              )
                            },
                            expression:
                              "publishForm.arbAdditionInfo.deliverModeDay",
                          },
                        }),
                        _vm._v("】个工作日内提货 "),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "form-item-invsStatus",
                    attrs: {
                      label: "质保方式：",
                      prop: "arbAdditionInfo.qualityMode",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.publishForm.arbAdditionInfo.qualityMode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.publishForm.arbAdditionInfo,
                              "qualityMode",
                              $$v
                            )
                          },
                          expression: "publishForm.arbAdditionInfo.qualityMode",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "GZB" } }, [
                          _vm._v("供应商质保"),
                        ]),
                        _c("el-radio", { attrs: { label: "ORTHER" } }, [
                          _vm._v("其他"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form-item-invsStatus",
                    style: {
                      color:
                        _vm.publishForm.arbAdditionInfo.qualityMode == "GZB"
                          ? "#000"
                          : "#c0c4cc",
                    },
                    attrs: { prop: "arbAdditionInfo.qualityTerm" },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { "font-size": "14px" } },
                      [
                        _vm._v(" 通过验收之日起【"),
                        _c("el-input", {
                          staticClass: "input-width-small",
                          attrs: {
                            size: "mini",
                            disabled:
                              _vm.publishForm.arbAdditionInfo.qualityMode ==
                              "GZB"
                                ? false
                                : true,
                          },
                          model: {
                            value: _vm.publishForm.arbAdditionInfo.qualityTerm,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.publishForm.arbAdditionInfo,
                                "qualityTerm",
                                $$v
                              )
                            },
                            expression:
                              "publishForm.arbAdditionInfo.qualityTerm",
                          },
                        }),
                        _vm._v("】年 "),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "form-item",
                    attrs: {
                      label: "交货安排：",
                      prop: "arbAdditionInfo.deliverSet",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.deliverSetMethod(
                              _vm.publishForm.arbAdditionInfo.deliverSet
                            )
                          },
                        },
                        model: {
                          value: _vm.publishForm.arbAdditionInfo.deliverSet,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.publishForm.arbAdditionInfo,
                              "deliverSet",
                              $$v
                            )
                          },
                          expression: "publishForm.arbAdditionInfo.deliverSet",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "QK" } }, [
                          _vm._v("一次性交货"),
                        ]),
                        _c("el-radio", { attrs: { label: "FQ" } }, [
                          _vm._v("分期交货"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.publishForm.arbAdditionInfo.deliverSet == "FQ"
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: {
                          label: "分期交货：",
                          prop: "arbAdditionInfo.fqJhTermIndex",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            placeholder: "1-24期",
                            size: "mini",
                            onkeyup: "value=value.replace(/[^\\d]/g,'')",
                            maxlength: "2",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.inputChange(
                                _vm.publishForm.arbAdditionInfo.fqJhTermIndex
                              )
                            },
                          },
                          model: {
                            value:
                              _vm.publishForm.arbAdditionInfo.fqJhTermIndex,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.publishForm.arbAdditionInfo,
                                "fqJhTermIndex",
                                $$v
                              )
                            },
                            expression:
                              "publishForm.arbAdditionInfo.fqJhTermIndex",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _vm.publishForm.arbAdditionInfo.deliverSet == "QK"
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: {
                          label: "一次性交货：",
                          prop: "arbAdditionInfo.deliverSetPort",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "input-width",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.publishForm.arbAdditionInfo.deliverSetPort,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.publishForm.arbAdditionInfo,
                                  "deliverSetPort",
                                  $$v
                                )
                              },
                              expression:
                                "publishForm.arbAdditionInfo.deliverSetPort",
                            },
                          },
                          _vm._l(
                            _vm.paymentmethod.arrangementDelivery,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.publishForm.arbAdditionInfo.deliverSet == "QK" &&
                _vm.publishForm.arbAdditionInfo.deliverSetPort == "QD"
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { prop: "arbAdditionInfo.deliverSetDay" },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "font-size": "14px" } },
                          [
                            _vm._v(" 签订合同之日起【"),
                            _c("el-input", {
                              staticClass: "input-width-small",
                              attrs: { size: "mini" },
                              model: {
                                value:
                                  _vm.publishForm.arbAdditionInfo.deliverSetDay,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.publishForm.arbAdditionInfo,
                                    "deliverSetDay",
                                    $$v
                                  )
                                },
                                expression:
                                  "publishForm.arbAdditionInfo.deliverSetDay",
                              },
                            }),
                            _vm._v("】个工作日内 "),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.publishForm.arbAdditionInfo.deliverSet == "QK" &&
                _vm.publishForm.arbAdditionInfo.deliverSetPort == "SK"
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { prop: "arbAdditionInfo.deliverSetDay" },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "font-size": "14px" } },
                          [
                            _vm._v(" 收到全部贷款之日起【"),
                            _c("el-input", {
                              staticClass: "input-width-small",
                              attrs: { size: "mini" },
                              model: {
                                value:
                                  _vm.publishForm.arbAdditionInfo.deliverSetDay,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.publishForm.arbAdditionInfo,
                                    "deliverSetDay",
                                    $$v
                                  )
                                },
                                expression:
                                  "publishForm.arbAdditionInfo.deliverSetDay",
                              },
                            }),
                            _vm._v("】个工作日内 "),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.publishForm.arbAdditionInfo.deliverSet == "FQ" &&
            _vm.deliverSetList
              ? _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", "margin-bottom": "30px" },
                    attrs: {
                      data: _vm.publishForm.payDeliverRateJhList,
                      "highlight-current-row": "",
                      border: "",
                      align: "center",
                      "row-style": { height: 0 + "px" },
                      "cell-style": { padding: 0 + "px" },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "termIndex", label: "期数" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " 第" + _vm._s(scope.row.termIndex) + "期 "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1929236414
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "rate", label: "交货比例" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "payDeliverRateJhList." +
                                        scope.$index +
                                        ".rate",
                                      rules: _vm.publishFormRules.JHrate,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "table-input-width",
                                      attrs: {
                                        size: "mini",
                                        oninput:
                                          "value=value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3').replace(/^\\./g, '')",
                                      },
                                      model: {
                                        value: scope.row.rate,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "rate", $$v)
                                        },
                                        expression: "scope.row.rate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3392125976
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "支付时间" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "payDeliverRateJhList." +
                                        scope.$index +
                                        ".date",
                                      rules: _vm.publishFormRules.JHdate,
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "table-input-width",
                                      attrs: {
                                        size: "mini",
                                        type: "date",
                                        "value-format": "yyyy-MM-dd",
                                        "picker-options":
                                          _vm.pickerStartOptions,
                                        placeholder: "请选择开始时间",
                                      },
                                      model: {
                                        value: scope.row.date,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "date", $$v)
                                        },
                                        expression: "scope.row.date",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2238758202
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "btnBoxStyle" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btnLast",
                    attrs: { type: "primary" },
                    on: { click: _vm.last },
                  },
                  [_vm._v("上一步")]
                ),
                this.examineShow !== true
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btnNext",
                        attrs: { type: "primary" },
                        on: { click: _vm.next },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$route.query.isEdit === "true"
                              ? "修改"
                              : "立即发布"
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="publish-container">
    <div class="form-content">
        <!-- 遮罩 -->

      <div class="transparentBox" v-if="examineShow"></div>
      <el-form
        inline
        :model="publishForm"
        :rules="publishFormRules"
        ref="publishForm"
        label-width="130px"
      >
        <!-- {{ publishForm }}
        bidId \\\\\{{ bidId }}
        isbidId -->
        <!-- {{ isbidId }} -->
        <el-form-item style="margin-left:190px"  label="竞买要求：" prop="reqRemark">
          <tinymce-editor
            :value="publishForm.reqRemark"
            @input="publishForm.reqRemark = $event"
            style="width: 672px；"
          ></tinymce-editor>
        </el-form-item>
        <div class="btnBoxStyle">
          <el-button class="btnLast" type="primary" @click="last">上一步</el-button>

          <el-button class="btnNext" type="primary" @click="next">下一步</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  getREContract,
  addProProduct,
  getProducDetail,
  updateProProduct,
  categoryAll,
  userAddressList,
  uploadPath,
  getContractInfo,
} from "../../../../api";
import tinymceEditor from "@/components/tinymceEditor.vue";
import Title from "../../../../component/Title";
import { regRule } from "@/utils/validate";
export default {
  components: {
    Title,
    tinymceEditor,
  },
  props: {
    publishForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    bidId: {
      type: Number,
      default: () => {
        return {};
      },
    },
        examineShow: {
      type: Boolean,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    $route: function (to) {
      if (Object.keys(to.query).length === 0) this.initializationData();
    },
    bidId: function (val) {
      this.id = val;
      console.log("idd", this.id);
    },

    "publishForm.orderProcess": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "HK" && oldVal == "KH") {
        this.publishForm.arbAdditionInfo.invsStatus = "KQ";
      }
      if (newVal == "KH" && oldVal == "HK") {
        this.publishForm.arbAdditionInfo.invsStatus = "KH";
      }
    },
    "publishForm.arbAdditionInfo.paySet": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "FQ") {
        this.publishForm.arbAdditionInfo.paySetPort = "";
        // this.publishForm.arbAdditionInfo.paySetDay = "";
      }
      if (newVal == "QK" && oldVal !== "") {
        // this.publishForm.arbAdditionInfo.fqTermIndex = "";
        // this.paySetList = false;
        // this.deliverSetList = false;
      }
      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.publishForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.paySetList = true;
      }
    },

    "publishForm.arbAdditionInfo.deliverSet": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "FQ") {
        this.publishForm.arbAdditionInfo.deliverSetPort = "";
        // this.publishForm.arbAdditionInfo.deliverSetDay = "";
      }
      if (newVal == "QK" && oldVal !== "") {
        // this.publishForm.arbAdditionInfo.fqTermIndex = "";
        // this.paySetList = false;
        // this.deliverSetList = false;
      }
      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.publishForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.deliverSetList = true;
      }
    },

    "publishForm.arbAdditionInfo.deliverMode": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "SH" && oldVal == "ZT") {
        this.publishForm.arbAdditionInfo.deliverModeDay = "";
      }
      // if (newVal == 'ZT' && oldVal == 'SH') {
      //   this.publishForm.arbAdditionInfo.deliverModeDay = '';
      // }
    },
    "publishForm.arbAdditionInfo.qualityMode": function (newVal, oldVal) {
      if (newVal == "ORTHER" && oldVal == "GZB") {
        this.publishForm.arbAdditionInfo.qualityTerm = "";
      }
      // if (newVal == 'GZB' && oldVal == 'ORTHER') {
      //   this.publishForm.arbAdditionInfo.qualityTerm = '';
      // }
    },
    "publishForm.arbAdditionInfo.invsStatus": function (newVal, oldVal) {
      if (newVal == "KQ" && oldVal == "KH") {
        this.publishForm.arbAdditionInfo.invsDay = "";
      }
    },
  },
  data() {
    let validatePrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error(rule.msg));
      } else if (!regRule.number1.test(value)) {
        return callback(new Error("请输入正确的报价"));
      } else if (value >= 100000000) {
        return callback(new Error("报价金额需小于 100000000 元"));
      } else {
        callback();
      }
    };

    var validatemaxPrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("请输入最高竞买价"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("输入值不得等于0"));
      } else if (value >= 10000000000) {
        return callback(new Error("最高竞买价需小于 10000000000 元"));
      } else {
        callback();
      }
    };
    var validateStepPrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("请输入价格调整幅度"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("输入值不得等于0"));
      } else if (value >= 10000000000) {
        return callback(new Error("价格调整幅度需小于 10000000000 元"));
      } else {
        callback();
      }
    };

    var validateStartTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else if (new Date(value).getTime() < Date.now() - 1000) {
        return callback(new Error("开始时间不能早于当前时间"));
      } else {
        callback();
      }
    };

    var validateStartTimeTable = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else {
        callback();
      }
    };
    var validateQualityTerm = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value > 180) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else if (value.indexOf(".") !== -1) {
        return callback(new Error("请输入 1 ~ 180之间的整数质保期限"));
      } else if (value.length !== 1 && value[0] == 0) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else {
        callback();
      }
    };

    var validateTaxRate = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value >= 100) {
        return callback(new Error("请输入 0 ~ 99.99 之间税率"));
      } else {
        callback();
      }
    };
    var validatePayAfterData = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value > 30) {
        return callback(new Error("请输入 1 ~ 30之间日期"));
      } else {
        callback();
      }
    };
    var validateReNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入需求量"));
      } else if (
        value.toString().indexOf(".") > 0 &&
        value.toString().split(".")[1].length > 6
      ) {
        return callback(new Error("最多保留六位小数"));
      } else {
        callback();
      }
    };

    var validateYhzzPercentage = (rule, value, callback) => {
      if (this.publishForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }
      if (
        this.publishForm.payMode.length == 1 &&
        this.publishForm.arbAdditionInfo.yhzzPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }
      // console.log(this.publishForm.payMode.indexOf("CDHP"));
      if (
        this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.cdhpPercentage =
          100 - parseFloat(this.publishForm.arbAdditionInfo.yhzzPercentage);
        return callback();
      }

      this.publishForm.arbAdditionInfo.cdhpPercentage = "";
      return callback();
    };

    var validateCdhpPercentage = (rule, value, callback) => {
      if (this.publishForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }

      if (
        this.publishForm.payMode.length == 1 &&
        this.publishForm.arbAdditionInfo.cdhpPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }

      if (
        this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.yhzzPercentage =
          100 - parseFloat(this.publishForm.arbAdditionInfo.cdhpPercentage);
        return callback();
      }
      this.publishForm.arbAdditionInfo.yhzzPercentage = "";
      return callback();
    };

    var validatePayMode = (rule, value, callback) => {
      if (
        this.publishForm.payMode.indexOf("YHZZ") == -1 &&
        this.publishForm.payMode.indexOf("CDHP") == -1
      ) {
        return callback(new Error("请选择付款方式"));
      }
      return callback();
    };

    var validateFQrate = (rule, value, callback) => {
      let sum = 0;
      this.publishForm.payDeliverRateFkList.map((item) => {
        // console.log(item.rate);
        sum = sum + parseFloat(item.rate);
      });
      // console.log("sum", sum);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateJHrate = (rule, value, callback) => {
      let sum = 0;
      this.publishForm.payDeliverRateJhList.map((item) => {
        sum = sum + parseFloat(item.rate);
      });
      // console.log(value);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateFQtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }
      this.publishForm.payDeliverRateJhList;

      let newList = [];

      this.publishForm.payDeliverRateJhList.map((item) => {
        newList.push(item.termIndex);
      });

      for (var i = 0; i < newList.length; i++) {
        if (newList.indexOf(newList[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };
    var validateJHtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }
      this.publishForm.payDeliverRateJhList;

      let newList = [];

      this.publishForm.payDeliverRateJhList.map((item) => {
        newList.push(item.termIndex);
      });

      for (var i = 0; i < newList.length; i++) {
        if (newList.indexOf(newList[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };

    var validatefqTermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入分期期数"));
      }
      if (/^(?:[1-9]\d*)$/.test(value) == false) {
        callback(new Error("请输入正整数"));
      }
      if (parseFloat(value) < 0 || parseFloat(value) > 24) {
        this.deliverSetList = false;
        this.paySetList = false;
        return callback(new Error("请输入期数需为1-24"));
      }
      this.deliverSetList = true;
      this.paySetList = true;
      return callback();
    };

    return {
      deliverSetList: false,
      paySetList: false,
      contractList: [],
      contractTemplate: "",
      contractDialogVisible: false,
      uploadPath,
      order: 1,
      unitList: ["吨", "公斤", "克", "件", "套", "根", "个", "平方米"],
      pickerStartOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1000 * 60 * 60 * 24;
        },
      },
      //   publishForm: {
      //     productName: "",
      //     categoryId: "",
      //     productSpec: "",
      //     maxPrice: "",
      //     reqNum: "",
      //     unit: "",
      //     picUrl: "",
      //     startTime: "",
      //     validDate: "",
      //     dateTime: "",
      //     date: "",
      //     time: "",
      //     reqRemark: "",
      //     addressId: "",
      //     deliverDay: "",
      //     stepPrice: "",
      //     // forceShow: '',
      //     contractInfo: "",
      //     orderProcess: "",
      //     payMode: [],
      //     bankBillTerm: "",
      //     invoiceType: "",

      //     //付款分期表格
      //     payDeliverRateFkList: [],
      //     //交货分期表格
      //     payDeliverRateJhList: [],
      //     arbAdditionInfo: {
      //       fqTermIndex: "",
      //       type: "RE", //竞卖
      //       taxRate: "", // 使用税率
      //       invsStatus: "", // 开票时间
      //       paySet: "", //付款安排
      //       deliverSet: "", //交付安排
      //       deliverMode: "", // 交货方式
      //       packageMode: "", //包装方式
      //       checkMode: "", //验收条件
      //       qualityMode: "", //质保方式
      //       qualityTerm: "", //质保的日期
      //       paySetPort: "", // 付款安排数据
      //       deliverSetPort: "", // 交付安排数据

      //       invsDay: "", //付款后开发票日期
      //       deliverModeDay: "", //买家自提的日期
      //       bankBillTerm: "", //承兑汇票期限
      //       payMode: "",
      //       //------------------------
      //       paySetDay: "", //付款安排一次性付款日期

      //       //---------------------------
      //       deliverSetDay: "", //交货安排一次性交货日期

      //       //-----------------------------
      //       payPercent: "", // 付款占比
      //       yhzzPercentage: "", //银行转账百分比
      //       cdhpPercentage: "", //承兑汇票百分比
      //     },
      //   },
      userAddressList: [],
      categoryList: [],
      publishFormRules: {
        picUrl: [
          {
            required: true,
            message: "请上传产品图片",
            trigger: ["blur", "change"],
          },
        ],
        productName: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
        ],
        categoryId: [
          {
            required: true,
            message: "请选择产品类型",
            trigger: ["blur", "change"],
          },
        ],
        productSpec: [
          { required: true, message: "请输入规格型号", trigger: "blur" },
        ],
        maxPrice: [
          {
            required: true,
            validator: validatemaxPrice,
            // message: "请输入最高竞买价",
            trigger: "blur",
          },
        ],
        reqNum: [{ required: true, validator: validateReNum, trigger: "blur" }],
        unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
        dateTime: [
          {
            required: true,
            validator: this.validateStartTime,
            trigger: ["blur", "change"],
          },
        ],
        addressId: [
          {
            required: true,
            message: "请选择收货信息",
            trigger: ["blur", "change"],
          },
        ],
        deliverDay: [
          { required: true, message: "请输入交货时间", trigger: "blur" },
        ],
        stepPrice: [
          {
            required: true,

            validator: validateStepPrice,
            // msg: "请输入价格调整幅度",
            trigger: "blur",
          },
        ],
        forceShow: [
          { required: true, message: "请输入预告显示时间", trigger: "blur" },
        ],
        contractInfo: [
          { required: true, message: "请选择合同模板", trigger: "blur" },
        ],
        orderProcess: [
          { required: true, message: "请选择订单模式", trigger: "blur" },
        ],
        payMode: [
          { required: true, message: "请选择付款方式", trigger: "blur" },
        ],
        bankBillTerm: [
          { required: true, message: "请选择汇票期限", trigger: "blur" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],
        forceShow: [
          { required: true, message: "请输入预告显示时间", trigger: "blur" },
        ],
        contractInfo: [
          { required: true, message: "请选择合同模板", trigger: "blur" },
        ],
        orderProcess: [
          { required: true, message: "请选择订单模式", trigger: "blur" },
        ],
        payMode: [
          { required: true, message: "请选择付款方式", trigger: "blur" },
        ],
        bankBillTerm: [
          { required: true, message: "请选择汇票期限", trigger: "blur" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],
        "arbAdditionInfo.taxRate": [
          {
            required: true,
            validator: validateTaxRate,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.invsStatus": [
          { required: true, message: "请选择开票时间", trigger: "change" },
        ],
        "arbAdditionInfo.paySet": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSet": [
          { required: true, message: "请选择交货安排", trigger: "change" },
        ],

        "arbAdditionInfo.invsDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.paySetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        // "arbAdditionInfo.acceptanceQualifiedDate": [
        //   {
        //     required: true,
        //     validator: validatePayAfterData,
        //     trigger: ["blur", "change"],
        //   },
        // ],
        "arbAdditionInfo.deliverSetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.paySetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        //百分比

        "arbAdditionInfo.yhzzPercentage": [
          {
            required: true,
            validator: validateYhzzPercentage,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.cdhpPercentage": [
          {
            required: true,
            validator: validateCdhpPercentage,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.deliverMode": [
          {
            required: true,
            message: "请选择交货方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.deliverModeDay": [
          {
            required: true,
            // message: "请选择提货日期",
            validator: validatePayAfterData,
            trigger: "change",
          },
        ],

        "arbAdditionInfo.packageMode": [
          {
            required: true,
            message: "请选择包装方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.checkMode": [
          {
            required: true,
            message: "请选择验收条件",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityMode": [
          {
            required: true,
            message: "请选择质保方式",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityTerm": [
          {
            required: true,
            validator: validateQualityTerm,
            trigger: "change",
          },
        ],
        "arbAdditionInfo.fqTermIndex": [
          {
            required: true,
            validator: validatefqTermIndex,
            trigger: ["blur", "change"],
          },
        ],

        FQdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        FQrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateFQrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        FQtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateFQtermIndex,

            trigger: ["blur", "change"],
          },
        ],
        paymodeList: [
          {
            required: true,
            message: "请选择方式",

            trigger: ["blur", "change"],
          },
        ],
        JHdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        JHrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateJHrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        JHtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateJHtermIndex,
            trigger: ["blur", "change"],
          },
        ],
      },
      productDetail: null,

      paymentmethod: {
        orderProcess: "",
        payMode: "",
        bankBillTerm: "",
        payListOption: [
          {
            value: "DJ",
            label: "定金",
          },
          {
            value: "ZBJ",
            label: "质保金",
          },
          {
            value: "1",
            label: "第1期",
          },
          {
            value: "2",
            label: "第2期",
          },
          {
            value: "3",
            label: "第3期",
          },
          {
            value: "4",
            label: "第4期",
          },
          {
            value: "5",
            label: "第5期",
          },
          {
            value: "6",
            label: "第6期",
          },
          {
            value: "7",
            label: "第7期",
          },
          {
            value: "8",
            label: "第8期",
          },
          {
            value: "9",
            label: "第9期",
          },
          {
            value: "10",
            label: "第10期",
          },
          {
            value: "11",
            label: "第11期",
          },
          {
            value: "12",
            label: "第12期",
          },
          {
            value: "13",
            label: "第13期",
          },
          {
            value: "14",
            label: "第14期",
          },
          {
            value: "15",
            label: "第15期",
          },
          {
            value: "16",
            label: "第16期",
          },
          {
            value: "17",
            label: "第17期",
          },
          {
            value: "18",
            label: "第18期",
          },
          {
            value: "19",
            label: "第19期",
          },
          {
            value: "20",
            label: "第20期",
          },
          {
            value: "21",
            label: "第21期",
          },
          {
            value: "22",
            label: "第22期",
          },
          {
            value: "23",
            label: "第23期",
          },
          {
            value: "24",
            label: "第24期",
          },
        ],
        time: [
          {
            value: 1,
            label: "1个月",
          },
          {
            value: 3,
            label: "3个月",
          },
          {
            value: 6,
            label: "6个月",
          },
          {
            value: 9,
            label: "9个月",
          },
          {
            value: 12,
            label: "12个月",
          },
        ],
        arrangement: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "YS",
            label: "验收合格之后",
          },
        ],

        arrangementDelivery: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "SK",
            label: "全部收款之后",
          },
        ],
        billingTimeList: [
          {
            value: "KH",
            label: "付款后开发票",
          },
          {
            value: "KQ",
            label: "付款前开发票",
          },
        ],
        BuyerSelfDeliveryDate: [
          {
            value: 7,
            label: "7天",
          },
          {
            value: 14,
            label: "14天",
          },
        ],
        paymodeList: [
          {
            value: "YHZZ",
            label: "银行转账",
          },
          {
            value: "CDHP",
            label: "承兑汇票",
          },
        ],
      },
    };
  },
  created() {
    this.$store.commit("changeMenuType", "BUY");
    // if (this.$route.query.isEdit === "true") {
    //   this.getProducDetail();
    // }
    this.getAllCategory();
    this.getUserAddressList();
    this.getREContract();
  },
  methods: {
    keyup(e, val) {
      //限制12位整数 与两位小数的正则规则
      if (e.target.value[0] === ".") {
        e.target.value = e.target.value.replace(/^(\.)/, "0$1");
      } else if (e.target.value[0] === "0" && /\d/.test(e.target.value[1])) {
        e.target.value = e.target.value.substring(1);
      } else if (e.target.value.includes(".")) {
        const arr = e.target.value.split(".");
        if (arr[0].length > 10) {
          e.target.value = `${arr[0].substring(0, 12)}.${arr[1]}`;
          this.publishForm[val] = e.target.value;
        } else {
          e.target.value = e.target.value.replace(/[^\d\.]/g, "");
          e.target.value = e.target.value.replace(
            /^(\d{0,10}\.?\d{0,2}).*/,
            "$1"
          );
          this.publishForm[val] = e.target.value;
        }
      } else {
        e.target.value = e.target.value.replace(/[^\d]/g, "");
        e.target.value = e.target.value.replace(/^(\d{0,10}).*/, "$1");
        this.publishForm[val] = e.target.value;
      }
    },
    blurmethod(e, val) {
      if (e.target.value[e.target.value.length - 1] === ".") {
        e.target.value = e.target.value.substring(0, [
          e.target.value.length - 1,
        ]);
        this.publishForm[val] = e.target.value;
      }
    },
    initializationData() {
      this.deliverSetList = false;
      this.paySetList = false;
      this.contractTemplate = "";
      this.contractDialogVisible = false;
      this.publishForm = {
        productName: "",
        categoryId: "",
        productSpec: "",
        maxPrice: "",
        reqNum: "",
        unit: "",
        picUrl: "",
        startTime: "",
        validDate: "",
        dateTime: "",
        date: "",
        time: "",
        reqRemark: "",
        addressId: "",
        deliverDay: "",
        stepPrice: "",
        // forceShow: '',
        contractInfo: "",
        orderProcess: "",
        payMode: [],
        bankBillTerm: "",
        invoiceType: "",

        //付款分期表格
        payDeliverRateFkList: [],
        //交货分期表格
        payDeliverRateJhList: [],
        arbAdditionInfo: {
          fqTermIndex: "",
          type: "RE", //竞卖
          taxRate: "", // 使用税率
          invsStatus: "", // 开票时间
          paySet: "", //付款安排
          deliverSet: "", //交付安排
          deliverMode: "", // 交货方式
          packageMode: "", //包装方式
          checkMode: "", //验收条件
          qualityMode: "", //质保方式
          qualityTerm: "", //质保的日期
          paySetPort: "", // 付款安排数据
          deliverSetPort: "", // 交付安排数据

          invsDay: "", //付款后开发票日期
          deliverModeDay: "", //买家自提的日期
          bankBillTerm: "", //承兑汇票期限
          payMode: "",
          //------------------------
          paySetDay: "", //付款安排一次性付款日期

          //---------------------------
          deliverSetDay: "", //交货安排一次性交货日期

          //-----------------------------
          payPercent: "", // 付款占比
          yhzzPercentage: "", //银行转账百分比
          cdhpPercentage: "", //承兑汇票百分比
        },
      };

      this.paymentmethod.payMode = "";
      this.paymentmethod.bankBillTerm = "";
    },
    deliverSetMethod(newVal) {
      if (newVal == "QK" && this.publishForm.orderProcess == "KH") {
        this.publishForm.arbAdditionInfo.deliverSetPort = "SK";
      }
    },
    paySetMethod(newVal) {
      console.log("paySetMethod", newVal);
      if (newVal == "QK" && this.publishForm.orderProcess == "HK") {
        this.publishForm.arbAdditionInfo.paySetPort = "YS";
      }
    },
    inputChange(newVal) {
      console.log("newVal", newVal);
      let newTermIndex = parseFloat(newVal);

      this.publishForm.payDeliverRateFkList = [];
      this.publishForm.payDeliverRateJhList = [];
      // this.deliverSetList = true;
      // this.paySetList = true;
      for (var i = 0; i < newTermIndex; i++) {
        let obj = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          payMode: "",

          type: "FK",
        };

        this.publishForm.payDeliverRateFkList.push(obj);

        let obj1 = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          // payMode: "",

          type: "JH",
        };

        this.publishForm.payDeliverRateJhList.push(obj1);
      }
    },
    termIndexChange(val, value) {
      console.log(val, value);

      // for (let i = 0; i < this.paymentmethod.payListOption.length; i++) {
      //   if (value == this.paymentmethod.payListOption[i].value) {
      //     this.paymentmethod.payListOption.splice(i, 1);
      //     console.log(this.paymentmethod.payListOption.splice(i, 0));
      //     // break; // 有多个 id值 等于2时 不能break
      //   }
      // }

      this.publishForm.payDeliverRateJhList[val].termIndex = value;
    },
    JHtermIndexChange(val, value) {
      console.log(val, value);
      this.publishForm.payDeliverRateFkList[val].termIndex = value;
    },
    // paymentMethod(e) {
    //   if ((e = "YHZZ")) {
    //     this.publishForm.bankBillTerm = "";
    //   }
    // },
    addPayList() {
      if (parseInt(this.order) < 10) {
        this.order = this.order + 1;
        for (var i = 0; i < 1; i++) {
          var obj = {
            termIndex: "",
            rate: "",
            date: "",
            payMode: "",

            type: "FK",
          };
          this.publishForm.payDeliverRateFkList.push(obj);

          var obj1 = {
            termIndex: "",
            rate: "",
            date: "",
            payMode: "",

            type: "JH",
          };
          this.publishForm.payDeliverRateJhList.push(obj1);
        }
      }
    },
    // 减少收款人
    reducePayList() {
      if (this.publishForm.payDeliverRateJhList.length !== 0) {
        this.order = this.publishForm.payDeliverRateJhList.length;
      }
      if (this.publishForm.payDeliverRateFkList.length !== 0) {
        this.order = this.publishForm.payDeliverRateJhList.length;
      }
      if (parseInt(this.order) > 1) {
        this.order = this.order - 1;
        for (var i = 0; i < 1; i++) {
          var obj = {
            termIndex: "",
            rate: "",
            date: "",
            payMode: "",
            type: "FK",
          };
          this.publishForm.payDeliverRateFkList.pop(obj);
          var obj1 = {
            termIndex: "",
            rate: "",
            date: "",
            payMode: "",
            type: "JH",
          };
          this.publishForm.payDeliverRateJhList.pop(obj1);
        }
      }
    },

    paymentMethod(e) {
      if ((e = "YHZZ")) {
        this.publishForm.bankBillTerm = "";
      }
    },

    showContractDialog(content) {
      getContractInfo({
        id: content,
      }).then((res) => {
        if (res.errno === 0) {
          // this.contractInfo = res.data.list || [];
          this.contractTemplate = res.data.list[0].contractTextModel;
        }
      });
      this.contractDialogVisible = true;
    },
    getREContract() {
      getREContract({
        // page: 1,
        // limit: 100
      }).then((res) => {
        if (res.errno === 0) {
          this.contractList = res.data.list || [];
        }
      });
    },
    getTime() {
      let date = new Date();

      let h = date.getHours(),
        m = date.getMonth(),
        s = date.getSeconds();

      return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${
        s < 10 ? "0" + s : s
      }`;
    },
    getUserAddressList() {
      userAddressList({
        page: 1,
        limit: 999,
      }).then((res) => {
        if (res.errno === 0) {
          let list = res.data.items || [];
          list.forEach((item) => {
            item.info = `${item.name} ${item.mobile}  ${item.detailedAddress}`;
          });
          this.userAddressList = list;
        }
      });
    },
    handleUploadSuccess(res) {
      this.publishForm.picUrl = res.data.url;
      this.$refs.publishForm.validateField("picUrl");
    },
    // 图片上传之前
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //上一步
    last() {
          if (this.examineShow == true) {
        this.$emit("lastShow");
      } else {
      this.$emit("last", JSON.parse(JSON.stringify(this.publishForm)), this.id);
      }
    },
    //立即发布
    next() {
      //   this.publishForm.startTime = this.$moment(
      //     this.publishForm.startTime
      //   ).format("YYYY-MM-DD HH:mm:ss");
      //   this.publishForm.validDate = this.$moment(
      //     this.publishForm.validDate
      //   ).format("YYYY-MM-DD HH:mm:ss");
     if (this.examineShow == true) {
        this.$emit("nextShow");
      } else {

      this.$emit("next", JSON.parse(JSON.stringify(this.publishForm)));
      let publishForm = { ...this.publishForm };
      // publishForm.reqRemark = this.addPrompt();
      console.log(publishForm.reqRemark, "ceshi");

      this.editProduct();
      }
    },

    editProduct() {
      this.$refs.publishForm.validate((valid) => {
        if (valid) {
          this.publishForm.payMode = this.publishForm.payMode
            .toString()
            .replaceAll(",", "|");
          console.log(" this.publishForm.payMode", this.publishForm.payMode);
          this.publishForm.arbAdditionInfo.payMode = this.publishForm.payMode;
          //百分比

          let YHZZper = this.publishForm.arbAdditionInfo.yhzzPercentage;
          let CDHPper = this.publishForm.arbAdditionInfo.cdhpPercentage;
          let YHHPper = this.publishForm.arbAdditionInfo.yhhpPercentage;

          if (
            this.publishForm.payMode == [] ||
            this.publishForm.payMode == ""
          ) {
            this.publishForm.arbAdditionInfo.payPercent = "";
          } else if (
            this.publishForm.payMode.length == 14 ||
            this.publishForm.payMode.length == 9
          ) {
            if (YHZZper == "") {
              YHZZper = "0";
            }
            if (CDHPper == "") {
              CDHPper = "0";
            }
            if (YHHPper == "") {
              YHHPper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              YHZZper + "|" + CDHPper + "|" + YHHPper;
            console.log("payPercent1");
          } else if (
            this.publishForm.payMode.length == 4 &&
            this.publishForm.payMode.indexOf("YHZZ") !== -1
          ) {
            if (YHZZper == "") {
              YHZZper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              YHZZper + "|" + "0" + "|" + "0";
          } else if (
            this.publishForm.payMode.length == 4 &&
            this.publishForm.payMode.indexOf("CDHP") !== -1
          ) {
            if (CDHPper == "") {
              CDHPper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              "0" + "|" + CDHPper + "|" + "0";
          } else if (
            this.publishForm.payMode.length == 4 &&
            this.publishForm.payMode.indexOf("YHHP") !== -1
          ) {
            if (YHHPper == "") {
              YHHPper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              "0" + "|" + "0" + "|" + YHHPper;
          }

          this.publishForm.arbAdditionInfo.bankBillTerm =
            this.publishForm.bankBillTerm;

          let publishForm = { ...this.publishForm };

          updateProProduct({
            ...publishForm,
            id: this.id,
            steps: "1",
            //   id: this.$route.query.id,
            categoryId: Array.isArray(this.publishForm.categoryId)
              ? this.publishForm.categoryId[
                  this.publishForm.categoryId.length - 1
                ]
              : this.publishForm.categoryId,
          }).then((res) => {
            if (res.errno === 0) {
              // this.$message({
              //   type: "success",
              //   message: "发布成功！",
              // });
              this.$emit("addNext");
            }
          });
        }
      });
    },
    getAllCategory() {
      categoryAll().then((res) => {
        this.categoryList = res.data.map((parent) => {
          return {
            value: parent.id,
            label: parent.name,
            children: parent.child.map((child) => {
              return {
                value: child.id,
                label: child.name,
              };
            }),
          };
        });
      });
    },

    onValidityDateChange(value) {
      this.publishForm.startTime = `${value} ${this.publishForm.time[0]}:00`;
      this.publishForm.validDate = `${value} ${this.publishForm.time[1]}:00`;
      this.publishForm.dateTime = [
        this.publishForm.startTime,
        this.publishForm.validDate,
      ];
    },
    onValidityTimeChange(value) {
      this.publishForm.startTime = `${this.publishForm.date} ${value[0]}:00`;
      this.publishForm.validDate = `${this.publishForm.date} ${value[1]}:00`;
      this.publishForm.dateTime = [
        this.publishForm.startTime,
        this.publishForm.validDate,
      ];
    },
    validateStartTime(rule, value, callback) {
      if (!this.publishForm.date) {
        return callback(new Error("请选择开始日期"));
      } else if (!this.publishForm.time) {
        return callback(new Error("请选择开始时间"));
      } else if (new Date(value[0]).getTime() < Date.now()) {
        return callback(new Error("开始时间不能早于当前时间"));
      } else if (
        new Date(value[1]).getTime() >
        new Date(value[0]).getTime() + 1000 * 60 * 60 * 24
      ) {
        return callback(new Error("有效期时间不能大于一天"));
      } else {
        callback();
      }
    },

    // 根据选择添加付款方式和提示进合同
    // addPrompt() {
    //   let prompt = this.publishForm;
    //   if (prompt.orderProcess == "HK") {
    //     if (prompt.payMode == "YHZZ") {
    //       return  prompt.reqRemark;
    //     } else {
    //       return (
    //         `<p>付款方式：商业承兑汇票，承兑期限为${prompt.bankBillTerm}个月</p>` +
    //         prompt.reqRemark
    //       );
    //     }
    //   } else {
    //     if (prompt.payMode == "YHZZ") {
    //       return (
    //         `<p>付款方式：银行转账，签订合同后3天以内以银行转账的方式一次性付清</p>` +
    //         prompt.reqRemark
    //       );
    //     } else {
    //       return (
    //         `<p>付款方式：商业承兑汇票，在签订合同后3天以内以承兑期限为${prompt.bankBillTerm}个月的商业承兑汇票一次性付清</p>` +
    //         prompt.reqRemark
    //       );
    //     }
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-table__row current-row {
  height: 10px;
}

/deep/ .el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  text-align: center;
}
.contract-box {
  display: flex;

  .contract-left {
    flex: 1;
    position: relative;

    span {
      position: absolute;
    }
    .name {
      top: -8px;
      left: 0;
    }
    .num {
      bottom: -8px;
      left: 0;
    }
  }
}

.addPayList {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  margin: 0 0 30px 360px;
}
.reducePayList {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  margin-left: 30px;
}
.publish-container {
  background: #fff;
  padding-bottom: 20px;
  .form-content {
    padding-top: 20px;
    margin: 0 auto;
    width: 90%;
 margin-bottom: 30px;
    position: relative;
    .btnBoxStyle {
      .btnLast {
        position: absolute;
        right: 52%;
        z-index: 15;
      }
      .btnNext {
        position: absolute;
        right: 45%;
        z-index: 15;
      }
    }

    //遮罩
    .transparentBox {
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      z-index: 11;
    }
    .input-width {
      width: 300px;
      font-size: 12px;
    }
    .input-width-small {
      width: 60px;
    }
    .table-input-width {
      width: 250px;
      margin-top: 20px;
    }
    .capital {
      margin-top: 15px;
    }
    .form-item {
      margin-right: 0;
      margin-bottom: 30px;
      width: 50%;

      .hint {
        position: absolute;
        top: -22px;
        color: #999;
      }
    }
    .avatar {
      width: 148px;
      height: 148px;
      display: block;
    }
    .select-style {
      width: 300px;
      .el-select-dropdown__item {
        height: auto;
      }
    }
  }
}

/deep/.el-form-item__label {
  font-size: 12px;
  padding: 0;
}

/deep/.mce-tinymce {
  box-shadow: none;
}

.tag {
  color: #dc143c;
  font-size: 14px;
}
</style>

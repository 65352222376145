<template>
  <div class="publish-container">
    <div class="form-content">
      <!-- {{ publishForm }} \\\\\ isbidId
      {{ isbidId }} -->
      <div class="transparentBox" v-if="examineShow"></div>
      <el-form
        inline
        :model="publishForm"
        :rules="publishFormRules"
        ref="publishForm"
        label-width="130px"
      >
        <div class="basicBox">
          <div class="titleFont">基本信息</div>
          <div class="basic">
            <el-form-item label="产品图片：" prop="picUrl" style="width: 100%">
              <el-upload
                :action="uploadPath"
                list-type="picture-card"
                :show-file-list="false"
                :on-success="handleUploadSuccess"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="publishForm.picUrl"
                  :src="publishForm.picUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item
              class="form-item"
              label="订单模式："
              prop="orderProcess"
            >
              <!-- <el-radio-group v-model="publishForm.orderProcess">
                <el-radio label="HK">先货后款</el-radio>
                <el-radio label="KH">先款后货</el-radio>
              </el-radio-group> -->
              <el-select
                class="select-style"
                v-model="publishForm.orderProcess"
                placeholder="请选择"
                size="small"
                @change="changeOrderProcess(publishForm.orderProcess)"
              >
                <el-option
                  v-for="item in paymentmethod.orderProcess"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="form-item"
              label="产品名称："
              prop="productName"
            >
              <el-input
                class="input-width"
                v-model="publishForm.productName"
                placeholder="请输入产品名称"
                size="small"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="form-item"
              label="产品类型："
              prop="categoryId"
            >
              <el-cascader
                class="input-width"
                v-model="publishForm.categoryId"
                :options="categoryList"
                size="small"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              class="form-item"
              label="规格型号："
              prop="productSpec"
            >
              <el-input
                class="input-width"
                v-model="publishForm.productSpec"
                placeholder="请输入产品型号"
                size="small"
                maxlength="20"
              ></el-input>
            </el-form-item>

            <el-form-item class="form-item" label="需求量：" prop="reqNum">
              <el-input
                class="input-width"
                v-model="publishForm.reqNum"
                maxlength="10"
                placeholder="请输入需求量"
                style="width: 172px; margin-right: 8px"
                size="small"
                @input.native="valueReqNum($event, 'reqNum')"
              ></el-input>
              <el-select
                v-model="publishForm.unit"
                placeholder="请选择单位"
                style="width: 120px"
                allow-create
                filterable
                default-first-option
                size="small"
              >
                <el-option
                  v-for="(item, index) in unitList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="form-item"
              size="small"
              label="合同模板："
              prop="contractInfo"
            >
              <el-select
                class="contract-select"
                v-model="publishForm.contractInfo"
                placeholder="请选择合同模板"
                style="width: 300px"
                :popper-append-to-body="false"
              >
                <el-option
                  class="contract-option"
                  id="contract-id"
                  v-for="item in contractList"
                  :key="item.tContractTextNo"
                  :label="item.tContractTextName"
                  :value="item.tContractTextNo"
                >
                  <div class="contract-box">
                    <div class="contract-left">
                      <span class="name">{{ item.tContractTextName }}</span>
                      <span class="num">{{ item.tContractTextNo }}</span>
                    </div>
                    <div
                      class="contract-right"
                      @click="showContractDialog(item.id)"
                      style="color: #8492a6"
                    >
                      查看
                    </div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="form-item"
              label="合同编号："
              prop="contractNumber"
            >
              <el-input
                class="input-width"
                v-model="publishForm.contractNumber"
                maxlength="50"
                placeholder="请输入合同编号"
                size="small"
                onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                @input.native="valueNumber($event, 'contractNumber')"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="basicBox">
          <div class="titleFont">价格信息</div>
          <div class="basic">
            <el-form-item
              class="form-item"
              label="最高竞买价(元)："
              prop="maxPrice"
            >
              <el-input
                class="input-width"
                v-model="publishForm.maxPrice"
                :maxlength="13"
                placeholder="请输入最高竞买价"
                size="small"
                @input.native="keyup($event, 'maxPrice')"
                @blur="blurmethod($event, 'maxPrice')"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="form-item"
              label="价格调整幅度(元)："
              prop="stepPrice"
            >
              <el-input
                class="input-width"
                v-model="publishForm.stepPrice"
                :maxlength="13"
                placeholder="请输入价格调整幅度"
                size="small"
                @input.native="keyup($event, 'stepPrice')"
                @blur="blurmethod($event, 'stepPrice')"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-item" label="有效期：" prop="dateTime">
              <el-date-picker
                style="width: 130px"
                size="small"
                v-model="publishForm.date"
                :picker-options="pickerStartOptions"
                @change="onValidityDateChange"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="开始日期"
              >
              </el-date-picker>
              <span @click="nowTime">
                <el-time-picker
                  style="width: 200px"
                  is-range
                  size="small"
                  v-model="publishForm.time"
                  @change="onValidityTimeChange"
                  value-format="HH:mm"
                  format="HH:mm"
                  range-separator="-"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                >
                </el-time-picker>
              </span>
            </el-form-item>

            <el-form-item class="form-item" label="收货信息：" prop="addressId">
              <el-select
                v-model="publishForm.addressId"
                placeholder="请选择收货信息"
                class="select-style"
                size="small"
                :popper-append-to-body="false"
              >
                <el-option
                  class="self-select"
                  v-for="item in userAddressList"
                  :key="item.id"
                  :label="item.info"
                  :value="item.id"
                >
                  <span>{{ item.name }} {{ item.mobile }}</span
                  ><br />
                  <span>{{ item.detailedAddress }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="basicBox">
          <div class="titleFont">价格信息</div>
          <div class="basic">
            <el-row>
              <el-form-item
                class="form-item"
                size="small"
                label="发票类型："
                prop="invoiceType"
              >
                <el-radio-group v-model="publishForm.invoiceType">
                  <el-radio label="0">专用发票</el-radio>
                  <el-radio disabled label="1">普通发票</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item
                class="form-item"
                label="适用税率(%)："
                prop="arbAdditionInfo.taxRate"
              >
                <el-input
                  class="input-width"
                  v-model="publishForm.arbAdditionInfo.taxRate"
                  placeholder="请输入税率"
                  size="mini"
                  @input.native="valueChange($event, 'taxRate')"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-form-item
              class="form-item"
              label="开票时间："
              prop="arbAdditionInfo.invsStatus"
            >
              <el-radio-group v-model="publishForm.arbAdditionInfo.invsStatus">
                <el-radio label="KQ">付款前开发票</el-radio>
                <el-radio label="KH">付款后开发票</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="form-item-invsDay"
              prop="arbAdditionInfo.invsDay"
            >
              <div
                style="font-size: 14px"
                :style="{
                  color:
                    publishForm.arbAdditionInfo.invsStatus == 'KH'
                      ? '#000'
                      : '#c0c4cc',
                }"
              >
                付款之日起【<el-input
                  :disabled="this.invsDayShow"
                  class="input-width-small"
                  v-model="publishForm.arbAdditionInfo.invsDay"
                  size="mini"
                ></el-input
                >】个工作日内开票
              </div>
            </el-form-item>
          </div>
        </div>

        <el-row :gutter="60">
          <el-col :span="21">
            <el-dialog
              title="合同模板"
              :visible.sync="contractDialogVisible"
              width="700px"
            >
              <div v-html="contractTemplate"></div>
            </el-dialog>
          </el-col>
        </el-row>

        <div class="btnBoxStyle">
          <el-button class="btnstyle" type="primary" @click="next"
            >下一步</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  getREContract,
  addProProduct,
  getProducDetail,
  updateProProduct,
  categoryAll,
  userAddressList,
  uploadPath,
  getContractInfo,
} from "../../../../api";
import tinymceEditor from "@/components/tinymceEditor.vue";
import Title from "../../../../component/Title";
import { regRule } from "@/utils/validate";
import moment from "moment";
export default {
  components: {
    Title,
    tinymceEditor,
  },
  props: {
    publishForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isbidId: {
      type: Number,
      default: () => {
        return {};
      },
    },
    lastid: {
      type: Number,
      default: () => {
        return {};
      },
    },
    examineShow: {
      type: Boolean,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    $route: function (to) {
      if (Object.keys(to.query).length === 0) this.initializationData();
    },

    "publishForm.orderProcess": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "HK" && oldVal == "KH") {
        this.publishForm.arbAdditionInfo.invsStatus = "KQ";
      }
      if (newVal == "KH" && oldVal == "HK") {
        this.publishForm.arbAdditionInfo.invsStatus = "KH";
      }
    },
    "publishForm.arbAdditionInfo.paySet": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "FQ") {
        this.publishForm.arbAdditionInfo.paySetPort = "";
      }

      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.publishForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.paySetList = true;
      }
    },

    "publishForm.arbAdditionInfo.deliverSet": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "FQ") {
        this.publishForm.arbAdditionInfo.deliverSetPort = "";
      }

      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.publishForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.deliverSetList = true;
      }
    },

    "publishForm.arbAdditionInfo.deliverMode": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "SH" && oldVal == "ZT") {
        this.publishForm.arbAdditionInfo.deliverModeDay = "";
      }
      if (newVal == "ZT" && oldVal == "SH") {
        this.publishForm.arbAdditionInfo.deliverModeDay = "";
      }
    },
    "publishForm.arbAdditionInfo.qualityMode": function (newVal, oldVal) {
      if (newVal == "ORTHER" && oldVal == "GZB") {
        this.publishForm.arbAdditionInfo.qualityTerm = "";
      }
      if (newVal == "GZB" && oldVal == "ORTHER") {
        this.publishForm.arbAdditionInfo.qualityTerm = "";
      }
    },
    "publishForm.arbAdditionInfo.invsStatus": function (newVal, oldVal) {
      if (newVal == "KQ" && oldVal == "KH") {
        this.publishForm.arbAdditionInfo.invsDay = "";
      }
      if (newVal == "KH") {
        this.invsDayShow = false;
      }
      if (newVal !== "KH") {
        this.invsDayShow = true;
      }
    },
  },
  data() {
    //产品名称的校验
    var validateBidName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入产品名称"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };
    //规格型号
    var validateProductSpec = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入规格型号"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };

    var validatemaxPrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("请输入最高竞买价"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("输入值不得等于0"));
      } else if (value >= 10000000000) {
        return callback(new Error("最高竞买价需小于 10000000000 元"));
      } else {
        callback();
      }
    };
    var validateStepPrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("请输入价格调整幅度"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("输入值不得等于0"));
      } else if (value >= 10000000000) {
        return callback(new Error("价格调整幅度需小于 10000000000 元"));
      } else {
        callback();
      }
    };

    var validateStartTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else if (new Date(value).getTime() < Date.now() - 1000) {
        return callback(new Error("开始时间不能早于当前时间"));
      } else {
        callback();
      }
    };

    var validateStartTimeTable = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else {
        callback();
      }
    };
    var validateQualityTerm = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value > 180) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else if (value.indexOf(".") !== -1) {
        return callback(new Error("请输入 1 ~ 180之间的整数质保期限"));
      } else if (value.length !== 1 && value[0] == 0) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else {
        callback();
      }
    };

    var validateTaxRate = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value >= 100) {
        return callback(new Error("请输入 0 ~ 99.99 之间税率"));
      } else {
        callback();
      }
    };
    var validatePayAfterData = (rule, value, callback) => {
      if (this.publishForm.arbAdditionInfo.invsStatus == "KH") {
        if (!value || isNaN(value) || value <= 0 || value > 180) {
          return callback(new Error("请输入 1 ~ 180之间日期"));
        } else if (value.toString().indexOf(".") != -1) {
          callback(new Error("请输入1 ~ 180之间的正整数")); //防止输入多个小数点
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    var validateInvsDay = (rule, value, callback) => {
      if (this.publishForm.arbAdditionInfo.invsStatus == "KH") {
        if (!value || isNaN(value) || value <= 0 || value > 180) {
          this.publishForm.arbAdditionInfo.invsDay = "";
          return callback(new Error("请输入 1 ~ 180之间日期"));
        } else if (value.toString().indexOf(".") != -1) {
          this.publishForm.arbAdditionInfo.invsDay = "";
          callback(new Error("请输入1 ~ 180之间的正整数")); //防止输入多个小数点
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validateReNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入需求量"));
      } else if (
        value.toString().indexOf(".") > 0 &&
        value.toString().split(".")[1].length > 5
      ) {
        return callback(new Error("最多保留四位小数"));
      } else {
        callback();
      }
    };

    var validateYhzzPercentage = (rule, value, callback) => {
      if (this.publishForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }
      if (
        this.publishForm.payMode.length == 1 &&
        this.publishForm.arbAdditionInfo.yhzzPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }
      // console.log(this.publishForm.payMode.indexOf("CDHP"));
      if (
        this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.cdhpPercentage =
          100 - parseFloat(this.publishForm.arbAdditionInfo.yhzzPercentage);
        return callback();
      }

      this.publishForm.arbAdditionInfo.cdhpPercentage = "";
      return callback();
    };

    var validateCdhpPercentage = (rule, value, callback) => {
      if (this.publishForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }

      if (
        this.publishForm.payMode.length == 1 &&
        this.publishForm.arbAdditionInfo.cdhpPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }

      if (
        this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.yhzzPercentage =
          100 - parseFloat(this.publishForm.arbAdditionInfo.cdhpPercentage);
        return callback();
      }
      this.publishForm.arbAdditionInfo.yhzzPercentage = "";
      return callback();
    };

    var validatePayMode = (rule, value, callback) => {
      if (
        this.publishForm.payMode.indexOf("YHZZ") == -1 &&
        this.publishForm.payMode.indexOf("CDHP") == -1
      ) {
        return callback(new Error("请选择付款方式"));
      }
      return callback();
    };

    var validateFQrate = (rule, value, callback) => {
      let sum = 0;
      this.publishForm.payDeliverRateFkList.map((item) => {
        // console.log(item.rate);
        sum = sum + parseFloat(item.rate);
      });
      // console.log("sum", sum);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateJHrate = (rule, value, callback) => {
      let sum = 0;
      this.publishForm.payDeliverRateJhList.map((item) => {
        sum = sum + parseFloat(item.rate);
      });
      // console.log(value);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateFQtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }
      this.publishForm.payDeliverRateJhList;

      let newList = [];

      this.publishForm.payDeliverRateJhList.map((item) => {
        newList.push(item.termIndex);
      });

      for (var i = 0; i < newList.length; i++) {
        if (newList.indexOf(newList[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };
    var validateJHtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }
      this.publishForm.payDeliverRateJhList;

      let newList = [];

      this.publishForm.payDeliverRateJhList.map((item) => {
        newList.push(item.termIndex);
      });

      for (var i = 0; i < newList.length; i++) {
        if (newList.indexOf(newList[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };

    var validatefqTermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入分期期数"));
      }
      if (/^(?:[1-9]\d*)$/.test(value) == false) {
        callback(new Error("请输入正整数"));
      }
      if (parseFloat(value) < 0 || parseFloat(value) > 24) {
        this.deliverSetList = false;
        this.paySetList = false;
        return callback(new Error("请输入期数需为1-24"));
      }
      this.deliverSetList = true;
      this.paySetList = true;
      return callback();
    };
    //合同编号的校验
    var validateContractNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入合同编号"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };

    return {
      deliverSetList: false,
      paySetList: false,
      contractList: [],
      contractTemplate: "",
      contractDialogVisible: false,
      uploadPath,
      order: 1,
      unitList: ["吨", "公斤", "克", "件", "套", "根", "个", "平方米"],
      pickerStartOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1000 * 60 * 60 * 24;
        },
      },
      //   publishForm: {

      //     productName: "",
      //     categoryId: "",
      //     productSpec: "",
      //     maxPrice: "",
      //     reqNum: "",
      //     unit: "",
      //     picUrl: "",
      //     startTime: "",
      //     validDate: "",
      //     dateTime: "",
      //     date: "",
      //     time: "",
      //     reqRemark: "",
      //     addressId: "",
      //     deliverDay: "",
      //     stepPrice: "",
      //     // forceShow: '',
      //     contractInfo: "",
      //     orderProcess: "",
      //     payMode: [],
      //     bankBillTerm: "",
      //     invoiceType: "",

      //     //付款分期表格
      //     payDeliverRateFkList: [],
      //     //交货分期表格
      //     payDeliverRateJhList: [],
      //     arbAdditionInfo: {
      //       fqTermIndex: "",
      //       type: "RE", //竞卖
      //       taxRate: "", // 使用税率
      //       invsStatus: "", // 开票时间
      //       paySet: "", //付款安排
      //       deliverSet: "", //交付安排
      //       deliverMode: "", // 交货方式
      //       packageMode: "", //包装方式
      //       checkMode: "", //验收条件
      //       qualityMode: "", //质保方式
      //       qualityTerm: "", //质保的日期
      //       paySetPort: "", // 付款安排数据
      //       deliverSetPort: "", // 交付安排数据

      //       invsDay: "", //付款后开发票日期
      //       deliverModeDay: "", //买家自提的日期
      //       bankBillTerm: "", //承兑汇票期限
      //       payMode: "",
      //       //------------------------
      //       paySetDay: "", //付款安排一次性付款日期

      //       //---------------------------
      //       deliverSetDay: "", //交货安排一次性交货日期

      //       //-----------------------------
      //       payPercent: "", // 付款占比
      //       yhzzPercentage: "", //银行转账百分比
      //       cdhpPercentage: "", //承兑汇票百分比
      //     },
      //   },
      userAddressList: [],
      invsDayShow: true,
      categoryList: [],
      publishFormRules: {
        picUrl: [
          {
            required: true,
            message: "请上传产品图片",
            trigger: ["blur", "change"],
          },
        ],
        productName: [
          { required: true, validator: validateBidName, trigger: "blur" },
        ],
        categoryId: [
          {
            required: true,
            message: "请选择产品类型",
            trigger: ["blur", "change"],
          },
        ],
        productSpec: [
          { required: true, validator: validateProductSpec, trigger: "blur" },
        ],
        maxPrice: [
          {
            required: true,
            validator: validatemaxPrice,
            // message: "请输入最高竞买价",
            trigger: "blur",
          },
        ],
        reqNum: [{ required: true, validator: validateReNum, trigger: "blur" }],
        unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
        dateTime: [
          {
            required: true,
            validator: this.validateStartTime,
            trigger: ["blur", "change"],
          },
        ],
        addressId: [
          {
            required: true,
            message: "请选择收货信息",
            trigger: ["blur", "change"],
          },
        ],
        deliverDay: [
          { required: true, message: "请输入交货时间", trigger: "blur" },
        ],
        stepPrice: [
          {
            required: true,

            validator: validateStepPrice,
            // msg: "请输入价格调整幅度",
            trigger: "blur",
          },
        ],
        forceShow: [
          { required: true, message: "请输入预告显示时间", trigger: "blur" },
        ],
        contractInfo: [
          { required: true, message: "请选择合同模板", trigger: "blur" },
        ],
        orderProcess: [
          { required: true, message: "请选择订单模式", trigger: "blur" },
        ],
        payMode: [
          { required: true, message: "请选择付款方式", trigger: "blur" },
        ],
        bankBillTerm: [
          { required: true, message: "请选择汇票期限", trigger: "blur" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],
        forceShow: [
          { required: true, message: "请输入预告显示时间", trigger: "blur" },
        ],
        contractInfo: [
          { required: true, message: "请选择合同模板", trigger: "blur" },
        ],
        payMode: [
          { required: true, message: "请选择付款方式", trigger: "blur" },
        ],
        bankBillTerm: [
          { required: true, message: "请选择汇票期限", trigger: "blur" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],
        contractNumber: [
          {
            required: true,
            validator: validateContractNumber,
            trigger: "blur",
          },
        ],
        "arbAdditionInfo.taxRate": [
          {
            required: true,
            validator: validateTaxRate,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.invsStatus": [
          { required: true, message: "请选择开票时间", trigger: "change" },
        ],
        "arbAdditionInfo.paySet": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSet": [
          { required: true, message: "请选择交货安排", trigger: "change" },
        ],

        "arbAdditionInfo.invsDay": [
          {
            required: true,
            validator: validateInvsDay,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.paySetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        // "arbAdditionInfo.acceptanceQualifiedDate": [
        //   {
        //     required: true,
        //     validator: validatePayAfterData,
        //     trigger: ["blur", "change"],
        //   },
        // ],
        "arbAdditionInfo.deliverSetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.paySetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        //百分比

        "arbAdditionInfo.yhzzPercentage": [
          {
            required: true,
            validator: validateYhzzPercentage,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.cdhpPercentage": [
          {
            required: true,
            validator: validateCdhpPercentage,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.deliverMode": [
          {
            required: true,
            message: "请选择交货方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.deliverModeDay": [
          {
            required: true,
            // message: "请选择提货日期",
            validator: validatePayAfterData,
            trigger: "change",
          },
        ],

        "arbAdditionInfo.packageMode": [
          {
            required: true,
            message: "请选择包装方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.checkMode": [
          {
            required: true,
            message: "请选择验收条件",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityMode": [
          {
            required: true,
            message: "请选择质保方式",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityTerm": [
          {
            required: true,
            validator: validateQualityTerm,
            trigger: "change",
          },
        ],
        "arbAdditionInfo.fqTermIndex": [
          {
            required: true,
            validator: validatefqTermIndex,
            trigger: ["blur", "change"],
          },
        ],

        FQdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        FQrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateFQrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        FQtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateFQtermIndex,

            trigger: ["blur", "change"],
          },
        ],
        paymodeList: [
          {
            required: true,
            message: "请选择方式",

            trigger: ["blur", "change"],
          },
        ],
        JHdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        JHrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateJHrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        JHtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateJHtermIndex,
            trigger: ["blur", "change"],
          },
        ],
      },
      productDetail: null,

      paymentmethod: {
        payMode: "",
        bankBillTerm: "",
        orderProcess: [
          {
            value: "HK",
            label: "先货后款",
          },
          {
            value: "KH",
            label: "先款后货",
          },
        ],
        payListOption: [
          {
            value: "DJ",
            label: "定金",
          },
          {
            value: "ZBJ",
            label: "质保金",
          },
          {
            value: "1",
            label: "第1期",
          },
          {
            value: "2",
            label: "第2期",
          },
          {
            value: "3",
            label: "第3期",
          },
          {
            value: "4",
            label: "第4期",
          },
          {
            value: "5",
            label: "第5期",
          },
          {
            value: "6",
            label: "第6期",
          },
          {
            value: "7",
            label: "第7期",
          },
          {
            value: "8",
            label: "第8期",
          },
          {
            value: "9",
            label: "第9期",
          },
          {
            value: "10",
            label: "第10期",
          },
          {
            value: "11",
            label: "第11期",
          },
          {
            value: "12",
            label: "第12期",
          },
          {
            value: "13",
            label: "第13期",
          },
          {
            value: "14",
            label: "第14期",
          },
          {
            value: "15",
            label: "第15期",
          },
          {
            value: "16",
            label: "第16期",
          },
          {
            value: "17",
            label: "第17期",
          },
          {
            value: "18",
            label: "第18期",
          },
          {
            value: "19",
            label: "第19期",
          },
          {
            value: "20",
            label: "第20期",
          },
          {
            value: "21",
            label: "第21期",
          },
          {
            value: "22",
            label: "第22期",
          },
          {
            value: "23",
            label: "第23期",
          },
          {
            value: "24",
            label: "第24期",
          },
        ],
        time: [
          {
            value: 1,
            label: "1个月",
          },
          {
            value: 3,
            label: "3个月",
          },
          {
            value: 6,
            label: "6个月",
          },
          {
            value: 9,
            label: "9个月",
          },
          {
            value: 12,
            label: "12个月",
          },
        ],
        arrangement: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "YS",
            label: "验收合格之后",
          },
        ],

        arrangementDelivery: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "SK",
            label: "全部收款之后",
          },
        ],
        billingTimeList: [
          {
            value: "KH",
            label: "付款后开发票",
          },
          {
            value: "KQ",
            label: "付款前开发票",
          },
        ],
        BuyerSelfDeliveryDate: [
          {
            value: 7,
            label: "7天",
          },
          {
            value: 14,
            label: "14天",
          },
        ],
        paymodeList: [
          {
            value: "YHZZ",
            label: "银行转账",
          },
          {
            value: "CDHP",
            label: "承兑汇票",
          },
        ],
      },
    };
  },
  created() {
    this.$store.commit("changeMenuType", "BUY");
    if (this.$route.query.isEdit === "true") {
      this.getProducDetail();
    }
    this.getAllCategory();
    this.getUserAddressList();
    this.getREContract();
  },
  methods: {
    keyup(e, val) {
      //限制12位整数 与两位小数的正则规则
      if (e.target.value[0] === ".") {
        e.target.value = e.target.value.replace(/^\./g, "");
        //赋值
        this.publishForm[val] = e.target.value;
      } else if (e.target.value[0] === "0" && /\d/.test(e.target.value[1])) {
        e.target.value = e.target.value.substring(1);
      } else if (e.target.value.includes(".")) {
        const arr = e.target.value.split(".");
        if (arr[0].length > 10) {
          e.target.value = `${arr[0].substring(0, 12)}.${arr[1]}`;
          //赋值
          this.publishForm[val] = e.target.value;
        } else {
          e.target.value = e.target.value.replace(/[^\d\.]/g, "");
          e.target.value = e.target.value.replace(
            /^(\d{0,10}\.?\d{0,2}).*/,
            "$1"
          );
          this.publishForm[val] = e.target.value;
        }
      } else {
        e.target.value = e.target.value.replace(/[^\d]/g, "");
        e.target.value = e.target.value.replace(/^(\d{0,10}).*/, "$1");
        this.publishForm[val] = e.target.value;
      }
    },
    blurmethod(e, val) {
      if (e.target.value[e.target.value.length - 1] === ".") {
        e.target.value = e.target.value.substring(0, [
          e.target.value.length - 1,
        ]);
        this.publishForm[val] = e.target.value;
      }
    },
    valueChange(e, val) {
      console.log(e.target.value);
      e.target.value = e.target.value.replace(/(^\s*)|(\s*$)/g, "");
      const reg = /[^\d.]/g;

      // 只能是数字和小数点，不能是其他输入
      e.target.value = e.target.value.replace(reg, "");

      // 保证第一位只能是数字，不能是点
      e.target.value = e.target.value.replace(/^\./g, "");
      // 小数只能出现1位
      e.target.value = e.target.value
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      // 小数点后面保留2位
      e.target.value = e.target.value.replace(
        /^(\-)*(\d+)\.(\d\d).*$/,
        "$1$2.$3"
      );
      //赋值
      this.publishForm.arbAdditionInfo[val] = e.target.value;
    },
    valueReqNum(e, val) {
      e.target.value = e.target.value.replace(/(^\s*)|(\s*$)/g, "");
      const reg = /[^\d.]/g;

      // 只能是数字和小数点，不能是其他输入
      e.target.value = e.target.value.replace(reg, "");

      // 保证第一位只能是数字，不能是点
      e.target.value = e.target.value.replace(/^\./g, "");
      // 小数只能出现1位
      e.target.value = e.target.value
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      // 小数点后面保留4位
      e.target.value = e.target.value.replace(
        /^(\-)*(\d+)\.(\d\d\d\d).*$/,
        "$1$2.$3"
      );
      //赋值
      this.publishForm[val] = e.target.value;
    },
    valueNumber(e, val) {
      e.target.value = e.target.value.replace(/[\u4E00-\u9FA5]/g, "");
      //赋值
      this.publishForm[val] = e.target.value;
    },
    changeOrderProcess(value) {
      console.log(value);
      if (value == "HK") {
        this.publishForm.arbAdditionInfo.invsStatus = "KQ";
      }
      if (value == "KH") {
        this.publishForm.arbAdditionInfo.invsStatus = "KH";
      }
    },
    initializationData() {
      this.deliverSetList = false;
      this.paySetList = false;
      this.contractTemplate = "";
      this.contractDialogVisible = false;
      this.publishForm = {
        productName: "",
        categoryId: "",
        productSpec: "",
        maxPrice: "",
        reqNum: "",
        unit: "",
        picUrl: "",
        startTime: "",
        validDate: "",
        dateTime: "",
        date: "",
        time: "",
        reqRemark: "",
        addressId: "",
        deliverDay: "",
        stepPrice: "",
        // forceShow: '',
        contractInfo: "",
        orderProcess: "",
        payMode: [],
        bankBillTerm: "",
        invoiceType: "",

        //付款分期表格
        payDeliverRateFkList: [],
        //交货分期表格
        payDeliverRateJhList: [],
        arbAdditionInfo: {
          fqTermIndex: "",
          type: "RE", //竞卖
          taxRate: "", // 使用税率
          invsStatus: "", // 开票时间
          paySet: "", //付款安排
          deliverSet: "", //交付安排
          deliverMode: "", // 交货方式
          packageMode: "", //包装方式
          checkMode: "", //验收条件
          qualityMode: "", //质保方式
          qualityTerm: "", //质保的日期
          paySetPort: "", // 付款安排数据
          deliverSetPort: "", // 交付安排数据

          invsDay: "", //付款后开发票日期
          deliverModeDay: "", //买家自提的日期
          bankBillTerm: "", //承兑汇票期限
          payMode: "",
          //------------------------
          paySetDay: "", //付款安排一次性付款日期

          //---------------------------
          deliverSetDay: "", //交货安排一次性交货日期

          //-----------------------------
          payPercent: "", // 付款占比
          yhzzPercentage: "", //银行转账百分比
          cdhpPercentage: "", //承兑汇票百分比
        },
      };

      this.paymentmethod.payMode = "";
      this.paymentmethod.bankBillTerm = "";
    },
    deliverSetMethod(newVal) {
      if (newVal == "QK" && this.publishForm.orderProcess == "KH") {
        this.publishForm.arbAdditionInfo.deliverSetPort = "SK";
      }
    },
    paySetMethod(newVal) {
      console.log("paySetMethod", newVal);
      if (newVal == "QK" && this.publishForm.orderProcess == "HK") {
        this.publishForm.arbAdditionInfo.paySetPort = "YS";
      }
    },
    inputChange(newVal) {
      console.log("newVal", newVal);
      let newTermIndex = parseFloat(newVal);

      this.publishForm.payDeliverRateFkList = [];
      this.publishForm.payDeliverRateJhList = [];
      // this.deliverSetList = true;
      // this.paySetList = true;
      for (var i = 0; i < newTermIndex; i++) {
        let obj = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          payMode: "",

          type: "FK",
        };

        this.publishForm.payDeliverRateFkList.push(obj);

        let obj1 = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          // payMode: "",

          type: "JH",
        };

        this.publishForm.payDeliverRateJhList.push(obj1);
      }
    },
    termIndexChange(val, value) {
      console.log(val, value);

      // for (let i = 0; i < this.paymentmethod.payListOption.length; i++) {
      //   if (value == this.paymentmethod.payListOption[i].value) {
      //     this.paymentmethod.payListOption.splice(i, 1);
      //     console.log(this.paymentmethod.payListOption.splice(i, 0));
      //     // break; // 有多个 id值 等于2时 不能break
      //   }
      // }

      this.publishForm.payDeliverRateJhList[val].termIndex = value;
    },
    JHtermIndexChange(val, value) {
      console.log(val, value);
      this.publishForm.payDeliverRateFkList[val].termIndex = value;
    },
    // paymentMethod(e) {
    //   if ((e = "YHZZ")) {
    //     this.publishForm.bankBillTerm = "";
    //   }
    // },

    paymentMethod(e) {
      if ((e = "YHZZ")) {
        this.publishForm.bankBillTerm = "";
      }
    },

    showContractDialog(content) {
      getContractInfo({
        id: content,
      }).then((res) => {
        if (res.errno === 0) {
          // this.contractInfo = res.data.list || [];
          this.contractTemplate = res.data.contractTextModel;
        }
      });
      this.contractDialogVisible = true;
    },
    getREContract() {
      getREContract({
        // page: 1,
        // limit: 100
      }).then((res) => {
        if (res.errno === 0) {
          this.contractList = res.data.list || [];
        }
      });
    },
    getTime() {
      let date = new Date();

      let h = date.getHours(),
        m = date.getMonth(),
        s = date.getSeconds();
      return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${
        s < 10 ? "0" + s : s
      }`;
    },
    getUserAddressList() {
      userAddressList({
        page: 1,
        limit: 999,
      }).then((res) => {
        if (res.errno === 0) {
          let list = res.data.items || [];
          list.forEach((item) => {
            item.info = `${item.name} ${item.mobile}  ${item.detailedAddress}`;
          });
          this.userAddressList = list;
        }
      });
    },
    handleUploadSuccess(res) {
      this.publishForm.picUrl = res.data.url;
      this.$refs.publishForm.validateField("picUrl");
    },
    // 图片上传之前
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //立即发布
    next() {
      //   if (localStorage.getItem('userPermission').indexOf('client:re:push') ==-1) {
      //   return this.$message.info("暂无修改权限，请联系管理员");
      // }
      if (this.examineShow == true) {
        this.$emit("nextShow");
      } else {
        this.publishForm.startTime = this.$moment(
          this.publishForm.startTime
        ).format("YYYY-MM-DD HH:mm:ss");
        this.publishForm.validDate = this.$moment(
          this.publishForm.validDate
        ).format("YYYY-MM-DD HH:mm:ss");

        this.$emit("next", JSON.parse(JSON.stringify(this.publishForm)));

        let publishForm = { ...this.publishForm };
        // publishForm.reqRemark = this.addPrompt();
        console.log(publishForm.reqRemark, "ceshi");

        if (this.$route.query.isEdit === "true") {
          this.editProduct();
        } else {
          if (this.isbidId == 0) {
            this.addProduct();
          } else {
            this.editProduct();
          }
        }
      }
    },
    addProduct() {
      this.$refs.publishForm.validate((valid) => {
        if (valid) {
          if (!this.publishForm.unit) {
            return this.$message.warning("请选择单位");
          }
          // if (new Date(this.publishForm.startTime).getTime() < new Date().getTime() + 1000 * 60 * 60 * 2) {
          //   return this.$message.warning('开始时间的设置需要大于当前时间两个小时！');
          // }
          if (
            new Date(this.publishForm.startTime).getTime() <=
            new Date().getTime()
          ) {
            return this.$message.warning("开始时间的设置需要大于当前时间！");
          }

          console.log("this.publishForm.payMode122");
          console.log("this.publishForm.payMode11", this.publishForm.payMode);

          this.publishForm.payMode = this.publishForm.payMode
            .toString()
            .replaceAll(",", "|");

          this.publishForm.arbAdditionInfo.payMode = this.publishForm.payMode;
          console.log("this.publishForm.payMode33", this.publishForm.payMode);

          // this.publishForm.arbAdditionInfo.payPercent =
          //   this.publishForm.arbAdditionInfo.yhzzPercentage +
          //   "|" +
          //   this.publishForm.arbAdditionInfo.cdhpPercentage;

          //百分比

          let YHZZper = this.publishForm.arbAdditionInfo.yhzzPercentage;
          let CDHPper = this.publishForm.arbAdditionInfo.cdhpPercentage;
          let YHHPper = this.publishForm.arbAdditionInfo.yhhpPercentage;

          if (
            this.publishForm.payMode == [] ||
            this.publishForm.payMode == ""
          ) {
            this.publishForm.arbAdditionInfo.payPercent = "";
          } else if (
            this.publishForm.payMode.length == 14 ||
            this.publishForm.payMode.length == 9
          ) {
            if (YHZZper == "") {
              YHZZper = "0";
            }
            if (CDHPper == "") {
              CDHPper = "0";
            }
            if (YHHPper == "") {
              YHHPper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              YHZZper + "|" + CDHPper + "|" + YHHPper;
            console.log("payPercent1");
          } else if (
            this.publishForm.payMode.length == 4 &&
            this.publishForm.payMode.indexOf("YHZZ") !== -1
          ) {
            if (YHZZper == "") {
              YHZZper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              YHZZper + "|" + "0" + "|" + "0";
          } else if (
            this.publishForm.payMode.length == 4 &&
            this.publishForm.payMode.indexOf("CDHP") !== -1
          ) {
            if (CDHPper == "") {
              CDHPper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              "0" + "|" + CDHPper + "|" + "0";
          } else if (
            this.publishForm.payMode.length == 4 &&
            this.publishForm.payMode.indexOf("YHHP") !== -1
          ) {
            if (YHHPper == "") {
              YHHPper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              "0" + "|" + "0" + "|" + YHHPper;
          }
          this.publishForm.arbAdditionInfo.bankBillTerm =
            this.publishForm.bankBillTerm;

          let publishForm = { ...this.publishForm };

          // publishForm.reqRemark = this.addPrompt();
          addProProduct({
            ...publishForm,
            categoryId:
              this.publishForm.categoryId[
                this.publishForm.categoryId.length - 1
              ],
            steps: "0",
          }).then((res) => {
            if (res.errno === 0) {
              // this.$message({
              //   type: "success",
              //   message: "发布成功！",
              // });
              this.bidId = res.data.reqId;
              this.$emit("getBidId", this.bidId);
            }
          });
        }
      });
    },
    editProduct() {
      this.$refs.publishForm.validate((valid) => {
        if (valid) {
          if (!this.publishForm.unit) {
            return this.$message.warning("请选择单位");
          }
          // if (new Date(this.publishForm.startTime).getTime() < new Date().getTime() + 1000 * 60 * 60 * 2) {
          //   return this.$message.warning('开始时间的设置需要大于当前时间两个小时！');
          // }
          if (
            new Date(this.publishForm.startTime).getTime() <=
            new Date().getTime()
          ) {
            return this.$message.warning("开始时间的设置需要大于当前时间！");
          }
          this.publishForm.payMode = this.publishForm.payMode
            .toString()
            .replaceAll(",", "|");
          console.log(" this.publishForm.payMode", this.publishForm.payMode);
          this.publishForm.arbAdditionInfo.payMode = this.publishForm.payMode;

          //百分比

          let YHZZper = this.publishForm.arbAdditionInfo.yhzzPercentage;
          let CDHPper = this.publishForm.arbAdditionInfo.cdhpPercentage;
          let YHHPper = this.publishForm.arbAdditionInfo.yhhpPercentage;

          if (
            this.publishForm.payMode == [] ||
            this.publishForm.payMode == ""
          ) {
            this.publishForm.arbAdditionInfo.payPercent = "";
          } else if (
            this.publishForm.payMode.length == 14 ||
            this.publishForm.payMode.length == 9
          ) {
            if (YHZZper == "") {
              YHZZper = "0";
            }
            if (CDHPper == "") {
              CDHPper = "0";
            }
            if (YHHPper == "") {
              YHHPper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              YHZZper + "|" + CDHPper + "|" + YHHPper;
            console.log("payPercent1");
          } else if (
            this.publishForm.payMode.length == 4 &&
            this.publishForm.payMode.indexOf("YHZZ") !== -1
          ) {
            if (YHZZper == "") {
              YHZZper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              YHZZper + "|" + "0" + "|" + "0";
          } else if (
            this.publishForm.payMode.length == 4 &&
            this.publishForm.payMode.indexOf("CDHP") !== -1
          ) {
            if (CDHPper == "") {
              CDHPper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              "0" + "|" + CDHPper + "|" + "0";
          } else if (
            this.publishForm.payMode.length == 4 &&
            this.publishForm.payMode.indexOf("YHHP") !== -1
          ) {
            if (YHHPper == "") {
              YHHPper = "0";
            }
            this.publishForm.arbAdditionInfo.payPercent =
              "0" + "|" + "0" + "|" + YHHPper;
          }
          this.publishForm.arbAdditionInfo.bankBillTerm =
            this.publishForm.bankBillTerm;

          let publishForm = { ...this.publishForm };
          // publishForm.reqRemark = this.addPrompt();
          updateProProduct({
            ...publishForm,
            id:
              this.isbidId == 0 && this.isEdit !== true
                ? this.$route.query.id
                : this.isbidId,
            steps: "0",
            categoryId: Array.isArray(this.publishForm.categoryId)
              ? this.publishForm.categoryId[
                  this.publishForm.categoryId.length - 1
                ]
              : this.publishForm.categoryId,
          }).then((res) => {
            if (res.errno === 0) {
              // this.$message({
              //   type: "success",
              //   message: "发布成功！",
              // });
              if (this.$route.query.id !== undefined) {
                this.bidId = this.$route.query.id;
                this.$emit("getBidId", this.bidId);
              } else {
                console.log("lastid", this.lastid);
                this.bidId = this.lastid;
                this.$emit("getBidId", this.bidId);
              }
            }
          });
        }
      });
    },
    getAllCategory() {
      categoryAll().then((res) => {
        this.categoryList = res.data.map((parent) => {
          return {
            value: parent.id,
            label: parent.name,
            children: parent.child.map((child) => {
              return {
                value: child.id,
                label: child.name,
              };
            }),
          };
        });
      });
    },
    //回显
    getProducDetail() {
      getProducDetail({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.errno === 0) {
          this.productDetail = res.data.repProductDetail;
          Object.keys(this.publishForm).forEach((key) => {
            this.publishForm[key] = res.data.repProductDetail[key];
          });
          this.publishForm.dateTime = [
            this.publishForm.startTime,
            this.publishForm.validDate,
          ];
          this.publishForm.date = this.publishForm.startTime.substr(0, 10);
          this.publishForm.time = [
            this.publishForm.startTime.substr(11, 8),
            this.publishForm.validDate.substr(11, 8),
          ];

          // 去掉付款方式的提示语句
          // let index = this.publishForm.reqRemark.indexOf("</p>");
          // this.publishForm.reqRemark = this.publishForm.reqRemark.substr(index);

          if (
            res.data.repProductDetail.arbAdditionInfo.payMode &&
            res.data.repProductDetail.payMode.indexOf("|") != -1
          ) {
            this.publishForm.payMode =
              res.data.repProductDetail.payMode.split("|");
          } else {
            if (res.data.repProductDetail.arbAdditionInfo.payMode == "") {
              this.publishForm.payMode = [];
            } else {
              this.publishForm.payMode = [res.data.repProductDetail.payMode];
            }
          }

          if (
            res.data.repProductDetail.arbAdditionInfo.fqTermIndex !== "" &&
            res.data.repProductDetail.arbAdditionInfo.paySet == "FQ"
          ) {
            this.paySetList = true;
          }
          if (
            res.data.repProductDetail.arbAdditionInfo.fqJhTermIndex !== "" &&
            res.data.repProductDetail.arbAdditionInfo.deliverSet == "FQ"
          ) {
            this.deliverSetList = true;
          }

          if (
            res.data.repProductDetail.arbAdditionInfo.deliverModeDay !== "" &&
            res.data.repProductDetail.arbAdditionInfo.deliverModeDay !==
              undefined
          ) {
            res.data.repProductDetail.arbAdditionInfo.deliverModeDay =
              res.data.repProductDetail.arbAdditionInfo.deliverModeDay.toString();
          } else {
            this.publishForm.arbAdditionInfo.deliverModeDay = "";
          }

          if (
            res.data.repProductDetail.arbAdditionInfo.qualityTerm !== "" &&
            res.data.repProductDetail.arbAdditionInfo.qualityTerm !== undefined
          ) {
            res.data.repProductDetail.arbAdditionInfo.qualityTerm =
              res.data.repProductDetail.arbAdditionInfo.qualityTerm.toString();
          } else {
            this.publishForm.arbAdditionInfo.qualityTerm = "";
          }
          //百分比
          if (res.data.repProductDetail.arbAdditionInfo.payPercent == "") {
            this.$set(this.publishForm.arbAdditionInfo, "yhzzPercentage", "");
            this.$set(this.publishForm.arbAdditionInfo, "cdhpPercentage", "");
            this.$set(this.publishForm.arbAdditionInfo, "yhhpPercentage", "");
          } else {
            let percentageArr =
              res.data.repProductDetail.arbAdditionInfo.payPercent.split("|");
            console.log("percentageArr", percentageArr);
            if (percentageArr[0] == "0" && percentageArr[0] !== undefined) {
              console.log("aaaaaa");

              this.publishForm.arbAdditionInfo.yhzzPercentage = "0";
              this.$set(
                this.publishForm.arbAdditionInfo,
                "cdhpPercentage",
                percentageArr[1]
              );

              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhhpPercentage",
                percentageArr[2]
              );
            } else if (
              percentageArr[1] == "0" &&
              percentageArr[1] !== undefined
            ) {
              console.log("bbbbbb");

              this.publishForm.arbAdditionInfo.cdhpPercentage = "0";
              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhhpPercentage",
                percentageArr[2]
              );

              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhzzPercentage",
                percentageArr[0]
              );
            } else if (
              percentageArr[2] == "0" &&
              percentageArr[2] !== undefined
            ) {
              console.log("cccccc");

              this.publishForm.arbAdditionInfo.yhhpPercentage = "0";
              this.$set(
                this.publishForm.arbAdditionInfo,
                "cdhpPercentage",
                percentageArr[1]
              );

              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhzzPercentage",
                percentageArr[0]
              );
            } else {
              this.$set(
                this.publishForm.arbAdditionInfo,
                "cdhpPercentage",
                percentageArr[1]
              );

              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhzzPercentage",
                percentageArr[0]
              );
              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhhpPercentage",
                percentageArr[2]
              );
            }
          }

          if (
            res.data.repProductDetail.arbAdditionInfo.paySet == "QK" &&
            res.data.repProductDetail.arbAdditionInfo.deliverSet == "FQ"
          ) {
            this.deliverSetList = true;
            this.publishForm.payDeliverRateFkList =
              res.data.repProductDetail.payDeliverRateJhList.map((item) => {
                return {
                  termIndex: item.termIndex,
                  rate: "",
                  date: "",
                  payMode: "",
                  type: "FK",
                };
              });
          }

          if (
            res.data.repProductDetail.arbAdditionInfo.deliverSet == "QK" &&
            res.data.repProductDetail.arbAdditionInfo.paySet == "FQ"
          ) {
            this.paySetList = true;
            this.publishForm.payDeliverRateJhList =
              res.data.repProductDetail.payDeliverRateFkList.map((item) => {
                return {
                  termIndex: item.termIndex,
                  rate: "",
                  date: "",
                  payMode: "",
                  type: "JH",
                };
              });
          }

          if (
            res.data.repProductDetail.arbAdditionInfo.deliverSet == "QK" &&
            res.data.repProductDetail.arbAdditionInfo.paySet == "QK"
          ) {
            this.publishForm.payDeliverRateFkList = [
              {
                termIndex: "",
                rate: "",
                date: "",
                payMode: "",
                type: "FK",
              },
            ];

            this.publishForm.payDeliverRateJhList = [
              {
                termIndex: "",
                rate: "",
                date: "",
                payMode: "",
                type: "JH",
              },
            ];
            // }
          }
        }
      });
    },
    onValidityDateChange(value) {
      this.publishForm.startTime = `${value} ${this.publishForm.time[0]}:00`;
      this.publishForm.validDate = `${value} ${this.publishForm.time[1]}:00`;
      this.publishForm.dateTime = [
        this.publishForm.startTime,
        this.publishForm.validDate,
      ];
    },
    onValidityTimeChange(value) {
      this.publishForm.startTime = `${this.publishForm.date} ${value[0]}:00`;
      this.publishForm.validDate = `${this.publishForm.date} ${value[1]}:00`;
      this.publishForm.dateTime = [
        this.publishForm.startTime,
        this.publishForm.validDate,
      ];
    },
    nowTime() {
      this.publishForm.time = [
        moment().format("HH:mm:ss"),
        moment().add(10, "minute").format("HH:mm:ss"),
      ];
    },
    validateStartTime(rule, value, callback) {
      if (!this.publishForm.date) {
        return callback(new Error("请选择开始日期"));
      } else if (!this.publishForm.time) {
        return callback(new Error("请选择开始时间"));
      } else if (new Date(value[0]).getTime() < Date.now()) {
        return callback(new Error("开始时间不能早于当前时间"));
      } else if (
        new Date(value[1]).getTime() >
        new Date(value[0]).getTime() + 1000 * 60 * 60 * 24
      ) {
        return callback(new Error("有效期时间不能大于一天"));
      } else {
        callback();
      }
    },

    // 根据选择添加付款方式和提示进合同
    // addPrompt() {
    //   let prompt = this.publishForm;
    //   if (prompt.orderProcess == "HK") {
    //     if (prompt.payMode == "YHZZ") {
    //       return  prompt.reqRemark;
    //     } else {
    //       return (
    //         `<p>付款方式：商业承兑汇票，承兑期限为${prompt.bankBillTerm}个月</p>` +
    //         prompt.reqRemark
    //       );
    //     }
    //   } else {
    //     if (prompt.payMode == "YHZZ") {
    //       return (
    //         `<p>付款方式：银行转账，签订合同后3天以内以银行转账的方式一次性付清</p>` +
    //         prompt.reqRemark
    //       );
    //     } else {
    //       return (
    //         `<p>付款方式：商业承兑汇票，在签订合同后3天以内以承兑期限为${prompt.bankBillTerm}个月的商业承兑汇票一次性付清</p>` +
    //         prompt.reqRemark
    //       );
    //     }
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-table__row current-row {
  height: 10px;
}

/deep/ .el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  text-align: center;
}

// /deep/.el-select-dropdown__wrap ul {
//   white-space: wrap !important;
//   height: 41px !important;
//   line-height: 14px !important;
//   background-color: red;
// }

/deep/ #contract-id {
  // background-color: skyblue !important;
  white-space: normal;
  overflow: visible;
  height: 70px;
  line-height: 10px;
  line-height: 20px;
  z-index: 9999;
}

.contract-box {
  display: flex;

  .contract-left {
    flex: 1;
    position: relative;

    span {
      position: absolute;
    }
    .name {
      display: block;
      word-wrap: break-word;
      top: 5px;
      left: 0;
    }
    .num {
      display: block;
      bottom: -48px;
      left: 0;
    }
  }
}
.addPayList {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  margin: 0 0 30px 360px;
}
.reducePayList {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  margin-left: 30px;
}
.publish-container {
  background: #fff;
  padding-bottom: 20px;
  .form-content {
    padding-top: 20px;
    margin: 0 auto;
    width: 80%;
    margin-bottom: 30px;
    position: relative;
    .btnBoxStyle {
      .btnstyle {
        position: absolute;
        right: 50%;
        z-index: 15;
      }
    }

    //遮罩
    .transparentBox {
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      z-index: 11;
    }
    .basicBox {
      position: relative;
      margin-top: 20px;
      .basic {
        border-top: 1px solid #eeeeee;
        padding-top: 38px;
        z-index: 1;
      }
      .titleFont {
        position: absolute;
        top: -15px;

        font-size: 18px;
        color: #a7b1c1;
        width: 80px;
        background-color: #fff;
        z-index: 2;
        margin-bottom: -5px;
      }
    }
    .input-width {
      width: 300px;
      font-size: 12px;
    }
    .form-item-invsDay {
      margin-left: -180px;
    }
    .input-width-small {
      width: 60px;
    }
    .table-input-width {
      width: 250px;
      margin-top: 20px;
    }
    .capital {
      margin-top: 15px;
    }
    .form-item {
      margin-right: 0;
      margin-bottom: 30px;
      width: 50%;

      .hint {
        position: absolute;
        top: -22px;
        color: #999;
      }
    }
    .avatar {
      width: 148px;
      height: 148px;
      display: block;
    }
    .select-style {
      width: 300px;
      .el-select-dropdown__item {
        height: auto;
      }
    }
  }
}

/deep/.el-form-item__label {
  font-size: 12px;
  padding: 0;
}

/deep/.mce-tinymce {
  box-shadow: none;
}

.tag {
  color: #dc143c;
  font-size: 14px;
}
</style>
